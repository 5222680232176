import React from "react";
import { CreateFile } from "./CreateFile";

export function QuotePhotos({ setFieldValue, data }) {
  const numberOfPhotos = 10;

  return data ? (
    <>
      <h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">Upload Site Photos</h2>
      <div className="grid grid-cols-2 gap-10">
        {Array.from({ length: numberOfPhotos }, (_, i) => (
          <div key={i + 1}>
            <h4 className="text-md font-normal leading-5 px-4">Photo - {i + 1}</h4>
            <CreateFile
              field={`quote_photo_${i + 1}`}
              setFieldValue={setFieldValue}
              type="edit"
              file={data.filter((file) => file.position === `quote_photo_${i + 1}`)[0]}
              // comment={data.filter((file) => file.position === `quote_photo_${i + 1}`)[0]?.comment}
            />
          </div>
        ))}
      </div>
    </>
  ) : null;
}
