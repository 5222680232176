import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchAllContacts(id) {
  const { data, error } = await supabase
    .from("client_contacts")
    .select("*")
    .match({client_id:id ,status: "Active" });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useContacts(id) {
  return useQuery("client_contacts", () => fetchAllContacts(id));
}
