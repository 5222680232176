import React from "react";
import moment from "moment";

const SumUpTable = ({ hireInvoices, edinvoices }) => {
  const filterInvoicesByDate = (invoices) => {
    const firstDayOfMonth = moment().startOf("month");
    const lastDayOfMonth = moment().endOf("month");
    return invoices.filter((item) => {
      const createdAt = moment(item.created_at);
      return createdAt.isBetween(firstDayOfMonth, lastDayOfMonth, null, "[]");
    });
  };

  const filteredHireInvoices = filterInvoicesByDate(hireInvoices);

  const filteredEdInvoices = filterInvoicesByDate(edinvoices);

  const sumErect = filteredEdInvoices.reduce((sum, item) => sum + (item?.erect || 0), 0);
  const sumDismantle = filteredEdInvoices.reduce((sum, item) => sum + (item?.dismantle || 0), 0);
  const sumEdTotal = Number(sumErect) + Number(sumDismantle)
  const sumHireTotal = filteredHireInvoices.reduce((sum, item) => {
    if (item?.total && item.total !== '' && !Number.isNaN(Number(item?.total))) {
      return Number(sum) + Number(item.total);
    }
    return Number(sum);
  }, 0);
  const data = [
    { title: "Erect Total", value: sumErect },
    { title: "Dismantle Total", value: sumDismantle },
    { title: "ED Total", value: sumEdTotal },
    { title: "Weekly Hire Total", value: sumHireTotal },
  ];
  return (
    <div className="flex">
      <div className="flex-grow bg-gray-100 p-1">
        <div className="mb-2 flex justify-center">Monthly Running Total</div>
        <div className="grid grid-cols-2 gap-1">
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <div className="bg-white p-2">{item.title}</div>
              <div className="bg-white p-2">{Number(item.value).toFixed(2)}</div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
export default SumUpTable;
