import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import footer from "../../../doc-footer.png";

const styles = StyleSheet.create({
  pageNumber: {
    marginTop: 10,
    marginRight: 17,
    fontSize: 8,
    textAlign: "center",
    color: "grey",
    bottom: 0,
  },
  footerLogo: {
    objectFit: "contain",
    width: "110%",
    marginRight: 0,
  },
  logo: {
    objectFit: "contain",
    width: "20%",
    height: "auto",
    paddingTop: 20,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    bottom: 7,
    right: 0,
  },
});

export const PageFooter = ({ client }) => (
  <View style={styles.footer} fixed>
    <Image style={styles.footerLogo} src={footer} fixed />
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  </View>
);
