import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

export async function createAppenateRecord(rows) {
  const tableId = "aac8e947-41ac-4faa-bb62-b10c0130c4d1";
  const externalId= "job_files";

  const response = await axios.post('https://metroscaff-server.herokuapp.com/api/data-sync', {
    id: "aac8e947-41ac-4faa-bb62-b10c0130c4d1",
    data: rows,
  });

  if (response.status !== 200) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.data;
}