import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";
import { createAppenateRecord } from "./createAppenateRecord";

import { useNotificationStore } from "../../store/notifications";

async function updateFile({ file, fileId }) {
  const { data, error } = await supabase.from("files").update(file).match({ id: fileId });

  if (data?.[0]?.job_id) {
    await createAppenateRecord([
      [
        data?.[0]?.id,
        data?.[0]?.job_id,
        data?.[0]?.file,
        data?.[0]?.file_description,
        data?.[0]?.notes,
        data?.[0]?.file_type,
        data?.[0]?.file_description,
      ],
    ]);
  }

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useUpdateFile() {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((payload) => updateFile(payload), {
    onSuccess: () => {
      queryClient.refetchQueries("files");
      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated file.`,
      });
    },
    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed to update file",
        content: err?.message,
      });
    },
    mutationFn: updateFile,
  });
}
