import { useState } from "react";
import moment from "moment";
import { useQueryClient } from "react-query";
import { DateSelect, Button, ConfirmationDialog } from "../../common";
import { useNotificationStore } from "../../store/notifications";
import supabase from "../../api/supabase";
import { StaffApi } from "../../api";

export const ApproveInvoices = ({ invoicesSelected, setInvoicesSelected, setStandardCompleteDate, setUpdateCount }) => {
    const { addNotification } = useNotificationStore();
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    const [completed, setCompleted] = useState(false)
    const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
    const { user } = supabase.auth.currentSession;
    const { data: authenticatedStaffRecord } = StaffApi.useStaffByEmail(user?.email);

    const approveInvoicesProcess = async () => {
        if (!invoicesSelected || authenticatedStaffRecord?.id === undefined || invoicesSelected.length === 0) {
            return
        }
        
    
        setTimeout(async () => {
          const uniqueJobs = Array.from(new Set(invoicesSelected.map((item) => item.job_id)));
          uniqueJobs.map(async (jobID) => {
            const invoices = invoicesSelected.filter((e) => e.job_id === jobID);
      
            // send items to xero to create new invoce
            fetch("https://metroscaff-server.herokuapp.com/xero-submit-invoice", {
              method: "POST", // or 'PUT'
              body: JSON.stringify({
                staff_id: authenticatedStaffRecord?.id, 
                invoice_data: {
                type: "invoices",
                endOfMonth: false,
                data: invoices,
                date,
                duplicate: false
              }}),
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((res) => res.json())
              .catch((error) =>
                addNotification({
                  isSuccess: false,
                  heading: "Error!",
                  content: `An error has occurred. The invoice was not created. Network Error With Xero.`,
                }),
              )
              .then((response) => {
                let isSuccess = true;
                let content = "Invoice created successfully";
                if (response?.err) {
                  isSuccess = false;
                  content = "Error!";
                }
                setStandardCompleteDate(moment().format("DD/MM/YYYY"));
                setCompleted(true);
                setIsLoading(false);
                setInvoicesSelected([]);
                queryClient.refetchQueries("weeklyHires");
                queryClient.refetchQueries("EDInvoices");
                const heading = response?.message;
                addNotification({
                  isSuccess,
                  heading,
                  content,
                });
              });
          });
          
          setTimeout(() => {
            setStandardCompleteDate(moment().format("DD/MM/YYYY"));
            setCompleted(true);
            setIsLoading(false);
            setInvoicesSelected([]);
            queryClient.refetchQueries("weeklyHires");
            queryClient.refetchQueries("EDInvoices");
            // window.location.reload();
            
        }, uniqueJobs.length * 5000);
        }, 1000);
        
       

    };

    const handleInputChange = (id, val) => {
      setDate(moment(val).format("DD/MM/YYYY"));
      setStandardCompleteDate(moment(val).format("DD/MM/YYYY"));
    }

    console.log(invoicesSelected);

    return (
        <ConfirmationDialog
            isDone={completed}
            icon="info"
            title="Approve Invoices"
            body="This action will send to xero the invoices selected. Select A Date for the process"
            triggerButton={
                <Button label="Approve Invoices"
                    style={{ backgroundColor: "#0078d4", borderRadius: "4px" }}
                    icon="submit"
                    className="p-button-raised p-3 flex align-items-center justify-content-center"
                >
                    Approve Invoices
                </Button>
            }
            confirmButton={
                <Button
                    isLoading={isLoading}
                    variant={invoicesSelected?.filter((e) => !e.completed_date && e.InvoiceType === 'Weekly Hire' ).length > 0 ? "declineInverse" : "approve"}
                    disabled={invoicesSelected?.filter((e) => !e.completed_date && e.InvoiceType === 'Weekly Hire' ).length > 0}
                    onClick={async (e) => {
                        setCompleted(false);
                        setIsLoading(true);
                        await approveInvoicesProcess();
                    }}
                >
                    Approve Invoices
                </Button >
            }
        >
            <div className="flex-1 pb-48 items-center justify-center">
            {invoicesSelected?.filter((e) => !e.completed_date && e.InvoiceType === 'Weekly Hire' ).length > 0 ? (
                    <p className="text-red-600 mt-10">Please select only hire invoices with a date completed</p>
                ) : null}
                <DateSelect
                    title="Approve Invoices"
                    id="date"
                    value={date}
                    onChange={handleInputChange}
                />

                <br />
            </div>
        </ConfirmationDialog >
    );
};