import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../../supabase";
import { checkJobStatus } from "./checkJobStatus";
import { useNotificationStore } from "../../../store/notifications";

async function updateTask({ payload, taskId }) {
  console.log("LOGS", payload, taskId);
  const { data, error } = await supabase.from("job_tasks").update(payload).match({ id: taskId });

  supabase
    .from("edinvoices")
    .update({
      PO_Number: payload?.PO_Number,
      zone: payload?.zone,
      zone_label: payload?.zone_label,
      type: payload?.type,
      description: payload?.description,
      comments: payload?.comments,
      erect_percent: payload?.percentage_erect,
      dismantle_percent: payload?.percentage_dismantle,
      complete_percent: payload?.percentage_complete,
    })
    .match({ task_id: taskId, status: "Pending" })
    .then((dataNewInvoice) => console.log(dataNewInvoice));

  supabase
    .from("weekly_hire_invoices")
    .update({
      type: payload?.type,
      zone_label: payload?.zone_label,
      zone: payload?.zone,
      description: payload?.description,
      completed: payload?.percentage_complete,
    })
    .match({ task_id: taskId, status: "Pending" })
    .then((dataInvoice) => {
      console.log(dataInvoice);
    });

  checkJobStatus(data[0].job_id);

  if (error) {
    throw new Error(error.message);
  }

  try {
    await updateAppenateTask(data);
  } catch (err) {
    console.log("Error updating task");
  }

  return data;
}

export function useUpdateTask() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();
  let refreshType = "";

  return useMutation(
    ({ payload, taskId, type = "tasks" }) => {
      refreshType = type;
      return updateTask({ payload, taskId });
    },
    {
      onSuccess: () => {
        console.log({ refreshType });
        queryClient.refetchQueries(refreshType);
        queryClient.refetchQueries("day_work_task");
        queryClient.refetchQueries("tasks");
        addNotification({
          isSuccess: true,
          heading: "Success!",
          content: `Successfully updated task.`,
        });
      },
      onError: (err) => {
        addNotification({
          isSuccess: false,
          heading: "Failed update task",
          content: err?.message,
        });
      },
      mutationFn: updateTask,
    },
  );
}

async function updateAppenateTask(tasks) {
  const taskPayload = [];

  tasks.map((task) =>
    taskPayload.push([
      task.id,
      task.job_id || "",
      task.zone || "",
      task.zone_label || "",
      task.type || "",
      task.description || "",
      task.complete || "",
    ]),
  );
  return axios.post("https://metroscaff-server.herokuapp.com/api/data-sync", {
    id: "091cc2e0-831e-4341-8108-af3500047302",
    data: taskPayload,
  });
}
