import React from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import { numberFormat } from "../../../../utils";

const borderColor = "#E5E7EB";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
    height: 13,
    fontFamily: "Open Sans",
    fontSize: 7.4,
    fontWeight: "semibold",
    color: "#1F2937",
  },
  description: {
    width: "60%",
    textAlign: "right",
    borderRightColor: borderColor,
    borderRightWidth: 1,
    paddingRight: "2",
  },
  erectTotal: {
    width: "20%",
    textAlign: "center",
    paddingRight: "8",
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  quantity: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  weeklyTotal: {
    width: "20%",
    textAlign: "left",
    // paddingRight: "8",
    paddingLeft: 33,
    fontFamily: "Open Sans",
    fontWeight: "bold",
    borderRightColor: borderColor,
    // borderRightWidth: 1,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingLeft: 8,
    paddingRight: 3,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
});

export const Footer = ({ items }) => {
  // Calculate totals
  const erect = items
    .map(
      (item) => Number(item.erect_dismantle),
    )
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const weekFee = items
    .map(
      (item) =>
        Number(item.weekly_fee),
    )
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const total = items
    .map((item) => Number(item.total))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <View style={styles.row}>
      <Text style={styles.description}>Total</Text>
      <Text style={styles.erectTotal}>{numberFormat.format(erect)}</Text>
      <Text style={styles.weeklyTotal}>{numberFormat.format(weekFee)}</Text>
    </View>
  );
};
