import { useQuery } from "react-query";

import supabase from "../../supabase";

export async function getQuoteNum() {
  // get next sequence value for id column in quotes
  const { data, error } = await supabase.rpc("get_next_quote_num");
  if (error) throw new Error(error.message);
  return data

}
