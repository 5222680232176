import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import moment from "moment";
import { Input, TextArea, Button, ConfirmationDialog } from "../../common";
import { QuotesApi, WeeklyHireApi, JobsApi } from "../../api";
import supabase from "../../api/supabase";
import { useNotificationStore } from "../../store/notifications";
import { uploadQuote } from "./pdf/uploadQuote";

export const EmailSent = ({ quoteId, emailStatus, quote }) => {
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const updateQuoteMutation = QuotesApi.useUpdateQuote();

  const clientContact = quote.client_contacts ? quote.client_contacts.name : " ";

  const [state, setState] = useState({
    subject: `Quote ${quote.quote_num}`,
    send_to: quote?.client_contacts?.email,
    body: `Hi ${clientContact}
Please click the __LINK__ below to view our quote for ${quote.street_1} ${quote.city}.
To accept the quote please email Metroscaff at <a href="mailto:sales@metroscaff.co.nz">sales@metroscaff.co.nz</a> with the quote number ${quote.quote_num}.

This quote is valid for 90 days.

Please do not reply directly to this email

Please note, acceptance of this quote mean acceptance of our <a href="https://www.metroscaff.co.nz/terms-and-conditions">terms and conditions</a>.

__LINK__`,
  });

  const newStatus = emailStatus === "Ready to send" ? "Sent" : "Ready to send";

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setState({
      ...state,
      [id]: value,
    });
  };

  return (
    <ConfirmationDialog
      isDone={updateQuoteMutation.isSuccess}
      icon="info"
      title="Sent Quote"
      body={`Are you sure you want to mark this quote as ${newStatus} ? ${
        newStatus === "Sent" ? "This action will send the quote to the client" : ""
      }`}
      triggerButton={
        <button
          type="button"
          id={quoteId}
          className="ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400"
        >
          {emailStatus === "Ready to send" && (
            <CheckIcon className="-ml-0.5 mr-2 h-4 w-4 text-green-400" aria-hidden="true" />
          )}
          {emailStatus === "Sent" && (
            <XIcon className="-ml-0.5 mr-2 h-4 w-4 text-red-400" aria-hidden="true" />
          )}
          {emailStatus}
        </button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            if (!state.body || !state.subject) {
              return;
            }
            setIsLoading(true);
            try {
              const result = await updateQuoteMutation.mutateAsync({
                quote: { emailStatus: newStatus },
                quoteId,
              });
            } catch (error) {
              console.log("error", error);
            }
            setIsLoading(false);
            if (newStatus === "Sent") {
              const urlQuote = await uploadQuote(quoteId);

              let htmlText = "";
              const messageTextArray = state.body.split("\n");

              messageTextArray.forEach((line) => {
                htmlText += `<p>${line}</p>`;
              });
              const link = `<a href="${urlQuote}">Link</a>`
              fetch("https://metroscaff-server.herokuapp.com/api/quote-email", {
                method: "POST",
                body: JSON.stringify({
                  subject: state.subject,
                  text: `${htmlText.replaceAll('__LINK__', link)} `,
                  emailTo: state.send_to,
                  sender: quote?.staff?.email || null,
                }),
                headers: {
                  "Content-Type": "application/json",
                },
              })
                .then((res) => res.json())
                .catch((error) =>
                  addNotification({
                    isSuccess: false,
                    heading: "Error!",
                    content: `Network Error With Xero.`,
                  }),
                )
                .then(async (response) => {
                  let isSuccess = true;
                  let content = "Success!";
                  if (response.err) {
                    isSuccess = false;
                    content = "Error!";
                  }
                  const heading = response.message;

                  addNotification({
                    isSuccess,
                    heading: "Sent email",
                    content: "Sent email!!!",
                  });
                  try {
                    const result = await updateQuoteMutation.mutateAsync({
                      quote: { emailStatus: newStatus, email_sent_date: moment().format("DD/MM/YYYY") },
                      quoteId,
                    });

                    window.location.reload();
                  } catch (error) {
                    console.log("error");
                  }
                  setIsLoading(false);
                });
            } else {
              try {
                const result = await updateQuoteMutation.mutateAsync({
                  quote: { emailStatus: newStatus },
                  quoteId,
                });
                window.location.reload();
              } catch (error) {
                console.log("error");
              }
              setIsLoading(false);
            }
          }}
        >
          Mark as {newStatus}
        </Button>
      }
      declineButton={<Button variant="inverse">Cancel</Button>}
    >
      <>
        {newStatus === "Sent" && (
          <>
            <div className="flex w-full">
              <div className="flex  w-full">
                <Input
                  title="Subject"
                  id="subject"
                  type="text"
                  value={state.subject}
                  handleChange={handleInputChange}
                  error={!state.subject ? "Subject is required" : null}
                />
                <Input
                  title="Send To"
                  id="send_to"
                  type="text"
                  value={state.send_to}
                  handleChange={handleInputChange}
                  error={!state.send_to ? "Email is required" : null}
                />
              </div>
            </div>
            <TextArea
              title="Body Text"
              id="body"
              type="text"
              rows={10}
              value={state.body}
              handleChange={handleInputChange}
              error={!state.body ? "Body is required" : null}
            />
          </>
        )}
      </>
    </ConfirmationDialog>
  );
};
