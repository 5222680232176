import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

export async function updateVisit({ visit, visitId, leader, jobNote }) {
  const { data, error } = await supabase.from("visits").update(visit).match({ id: visitId });

  if (error) {
    throw new Error(error.message);
  }

  // Update the job note
  const { data: jobData, error: jobError } = await supabase
    .from("jobs")
    .update({ note: jobNote })
    .eq("id", visit.job_id);

  if (jobError) {
    throw new Error(jobError.message);
  }

  const visitData = await supabase
    .from("visits")
    .select(
      "*, staff:team_leader_id(id, staff_name), jobs:job_id(id, job_num, site, note,clients:client_id(*)) ",
    )
    .eq("job_id", visit.job_id);
  console.log(visitData?.data, "visitData");
  let staffName = "";
  if (leader) {
    const staff = await supabase.from("staff").select("*").eq("id", leader);
    staffName = staff?.data?.[0]?.staff_name || "";
  }
  await updateAppenateVisits(visitData.data, staffName);

  return data;
}

export const useUpdateVisit = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((visit) => updateVisit(visit), {
    onSuccess: (data) => {
      queryClient.refetchQueries("visits");
      queryClient.refetchQueries("jobs");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated visit.`,
      });
    },

    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed updating visit",
        content: err?.message,
      });
    },
    mutationFn: updateVisit,
  });
};

async function updateAppenateVisits(data, staffName) {
  /*
    ROWS:
    id - Job Id - Job Display - date - vehicle Ids - vehicle display - staff ids - staff display - team leader id - task ids - task display - notes - status - risk factor - type -- additional tools - safety equipment - specialized PPE
  */
  const visitPayload = [];
  console.log(data, "datadatadata");
  for (const visit of data) {
    visitPayload.push([
      visit.id, // id
      visit.job_id, // job Id
      `${visit.job_id} - ${visit?.jobs?.clients?.client_name} - ${visit?.jobs?.site}`, // job display
      visit.date, // date
      "", // vehicle Ids
      visit.comments, // Visit Comments
      visit?.staff_ids?.join(",") || "",
      visit?.staff_labels?.join(",") || "",
      visit?.team_leader_id || "",
      staffName || "",
      visit?.task_ids?.join(",") || "",
      visit?.task_labels?.join(",") || "",
      visit?.jobs?.note, // Notes
      visit?.visit_status || "",
      "",
      visit?.risk || "",
      visit?.type || "",
      "",
      "",
      "",
      "",
      "",
      "",
      visit?.vehicle_ids?.join(",") || "" || "",
      visit?.vehicle_labels?.join(",") || "" || "",
      visit?.jobs?.clients?.client_name
    ]);
  }
  return axios.post("https://metroscaff-server.herokuapp.com/api/data-sync", {
    id: "5ba56dfe-a5a4-4073-a556-af3500055ebc",
    data: visitPayload,
  });
}
