export const markerIcon = `<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#"
xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape" xmlns="http://www.w3.org/2000/svg"
xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="svg2" inkscape:version="0.48.4 r9939"
sodipodi:docname="map-marker-blue.svg" x="0px" y="0px" width="1200px" height="1200px" viewBox="0 0 1200 1200"
enable-background="new 0 0 1200 1200" xml:space="preserve">
<path id="path14142" inkscape:connector-curvature="0"
    d="M600,0C350.178,0,147.656,202.521,147.656,452.344  c0,83.547,16.353,169.837,63.281,232.031L600,1200l389.063-515.625c42.625-56.49,63.281-156.356,63.281-232.031  C1052.344,202.521,849.822,0,600,0z M600,261.987c105.116,0,190.356,85.241,190.356,190.356C790.356,557.46,705.116,642.7,600,642.7  s-190.356-85.24-190.356-190.356C409.644,347.228,494.884,261.987,600,261.987z"
    fill="" />
</svg>`;
