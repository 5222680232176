import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

export async function updateMessage({ message, messageId }) {
  const { data, error } = await supabase.from("messages").update(message).match({ id: messageId });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateMessage = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((message) => updateMessage(message), {
    onSuccess: (data) => {
      queryClient.refetchQueries("messages");
      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated messages.`,
      });
    },

    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed updating messages",
        content: err?.message,
      });
    },
    mutationFn: updateMessage,
  });
};
