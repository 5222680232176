/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";

import { Formik } from "formik";
import { useNotificationStore } from "../../store/notifications";

import {
  SideModal,
  Input,
  TextArea,
  Dropdown,
  TimeSelect,
  Address,
  DateSelect,
  Button,
} from "../../common";

import { useActiveStaff } from "../../api/Staff";
import { useCreateToolboxTalk } from "../../api/ToolboxTalk/createToolboxTalk";
import { useFetchToolboxTalk } from "../../api/ToolboxTalk/getToolboxTalk";
import { useUpdateToolboxTalk } from "../../api/ToolboxTalk/updateToolboxTalk";
import { EditStaff } from "../Visits/Staff/EditStaff";

export function CreateToolboxTalk({
  heading,
  open,
  setOpen,
  formType = "create",
  id = 0,
  popup,
  setToolboxID,
}) {
  const typeOptions = [
    { value: "Employee", label: "Employee" },
    { value: "Foreman", label: "Foreman" },
    { value: "Office", label: "Office" },
    { value: "Scaffolder", label: "Scaffolder" },
    { value: "Truck Driver", label: "Truck Driver" },
    { value: "Application", label: "Application" },
    { value: "Contractor", label: "Contractor" },
    { value: "Yard Person", label: "Yard Person" },
  ];
  const createToolboxTalk = useCreateToolboxTalk();
  const updateToolboxTalk = useUpdateToolboxTalk();
  const { data, isLoading } = useFetchToolboxTalk(id);
  const [ToolboxTalkData, setToolboxTalkData] = useState();
  const { addNotification } = useNotificationStore();
  const [addTypes, setAddTypes] = useState([]);
  useEffect(() => {
    if (data) {
      setToolboxTalkData(data);
    }
    return () => {
      setToolboxTalkData(undefined);
    };
  }, [data]);

  if (id && !ToolboxTalkData) return null;
  if (isLoading) return null;

  return (
    <div>
      <Formik
        initialValues={{
          date: ToolboxTalkData?.date || moment().toDate(),
          description: ToolboxTalkData?.description || "",
          time: ToolboxTalkData?.time || "07:30",
          staff_ids: ToolboxTalkData?.staff_ids,
          staff_labels: ToolboxTalkData?.staff_labels,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.date) {
            errors.date = "Date is required.";
          }
          if (!values.staff_ids) {
            errors.staff = "Staff is required.";
          }
          if (!values.time) {
            errors.staff = "Time is required.";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (formType === "create") {
            const result = {
              date: moment(values.date, "DD/MM/YYYY").format("DD/MM/YYYY") || null,
              description: values.description,
              time: values.time,
              staff_ids: values?.staff_ids,
              staff_labels: values?.staff_labels,
            };

            try {
              const res = await createToolboxTalk.mutateAsync(result);
              const resp = await axios.post(
                "https://metroscaff-server.herokuapp.com/api/toolbox-message",
                {
                  result,
                },
              );
              const status = resp?.status;
              addNotification({
                isSuccess: status === 200 ? true : false,
                heading:
                  status === 200 ? "Successfully dispatched message" : "Failed to dispatch email",
                content:
                  status === 200 ? "Sent SMS to recipient." : "There was an error dispatching SMS",
              });
            } catch (err) {
              console.log("ERROR", err);
            }
          } else {
            const result = {
              date: moment(values.date, "DD/MM/YYYY").format("DD/MM/YYYY") || null,
              description: values.description,
              time: values.time,
              staff_ids: values?.staff_ids,
              staff_labels: values?.staff_labels,
            };

            try {
              const res = await updateToolboxTalk.mutateAsync({ id, toolboxTalk: result });
            } catch (err) {
              console.log("ERROR", err);
            }
          }

          setOpen(false);
          setToolboxID("");
          resetForm();
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading={heading}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType={formType}
            popup={popup}
            id={id}
          >
            <div className="flex items-center justify-between">
              <div className="w-1/2">
                <DateSelect
                  title="Date"
                  id="date"
                  value={values.date}
                  onChange={setFieldValue}
                  error={errors.date}
                  classNames="w-full"
                />
              </div>
              <div className="w-1/2">
                <TimeSelect
                  title="Time"
                  id="time"
                  value={
                    moment(values.time, "HH:mm").isValid()
                      ? moment(values.time, "HH:mm").toDate()
                      : ""
                  }
                  onChange={setFieldValue}
                  error={errors.time}
                  classNames="w-full"
                />
              </div>
            </div>

            <div className="flex items-center">
              <div className="w-full">
                <EditStaff
                  setFieldValue={setFieldValue}
                  values={values?.staff_ids}
                  error={errors.staff}
                  addTypes={addTypes}
                  setAddTypes={setAddTypes}
                />
                <div className="flex items-center">
                  <div className="px-4 py-4 flex-grow">
                    {" "}
                    <label
                      id="type"
                      htmlFor="type"
                      className="block mb-1 text-sm font-medium text-gray-700"
                    >
                      Type
                    </label>
                    <Select
                      isMulti
                      name="Type"
                      id="type"
                      options={typeOptions}
                      value={typeOptions.filter((option) => addTypes?.includes(option.value))}
                      onChange={(value) => {
                        const typesVals = value.map((item) => item.value);
                        setAddTypes(typesVals);
                      }}
                      className="w-full basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>
                  {Boolean(addTypes.length) && (
                    <div className="px-4 py-4 mt-6 flex-shrink-0">
                      <Button
                        type="button"
                        onClick={() => {
                          setAddTypes([]);
                          setFieldValue("staff_ids", []);
                          setFieldValue("staff_labels", []);
                        }}
                        variant="danger"
                        size="xs"
                      >
                        Clear selection
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <TextArea
              title="Description"
              id="description"
              type="text"
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values.description}
            />
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
