import axios from "axios";
import moment from "moment";
import supabase from "../supabase";

export const sendSMS = async (id) => {
  const { staffs, data } = await getStaffID(id);
  const staff = await getStaffData(staffs);
  const smsPromises = staff.map(async (staff, index) => {
    const smsMessage = `Hi ${staff?.staff_name}. Quick update on the talk scheduled for you. Date: ${data[index]?.date}, Time: ${data[index]?.time}, Description: ${data[index]?.description}`;
    axios.post("https://metroscaff-server.herokuapp.com/api/sms", {
      message: smsMessage,
      receiver: staff?.mobile,
    });
  });

  try {
    await Promise.all(smsPromises);
    console.log("SMS messages sent to all staff successfully");
  } catch (error) {
    console.error("Error sending SMS messages:", error);
  }
};
async function getStaffID() {
  const currentDate = moment().format("DD/MM/YYYY");
  const { data, error } = await supabase
    .from("toolbox_talk")
    .select("*")
    .eq("date", currentDate)
    .order("id", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }

  const staffIdsArray = data.map((obj) => obj.staff_ids);
  const flattenedStaffIds = staffIdsArray.flat();
  const staffs = [...new Set(flattenedStaffIds)];
  return { staffs, data };
}

async function getStaffData(staff_ids) {
  const { data, error } = await supabase
    .from("staff")
    .select("*")
    .in("id", staff_ids)
    .order("id", { ascending: false });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}
