import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllAssetCategories() {
  const { data, error } = await supabase
    .from("asset_categories")
    .select("*")
    .order("id", { ascending: false });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useAssetCategories() {
  return useQuery("asset_categories", () => fetchAllAssetCategories());
}
