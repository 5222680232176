import { XIcon } from "@heroicons/react/solid";

import { useFormikContext } from "formik";

import { Button } from "../../Button";
import { ConfirmationDialog } from "../../Confirmation/Confirmation";
import { sendSMS } from "../../../api/BurstSMS";

export function FormActions({ setOpen, isLoading, formType, popup = false, id }) {
  const { submitForm } = useFormikContext(); // Access the submitForm function from Formik

  const handleConfirm = async () => {
    try {
      await submitForm();
      await sendSMS(id);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };
  const handleDecline = () => {
    submitForm();
  };
  return (
    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
      <div className="space-x-3 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>

        {popup ? (
          <ConfirmationDialog
            icon="info"
            title="Do you want to send this change to all participants?"
            body="Are you sure you want to send this?"
            triggerButton={<Button>Update</Button>}
            confirmButton={<Button onClick={() => handleConfirm()}>Yes</Button>}
            declineButton={
              <Button variant="info" onClick={() => handleDecline()}>
                No
              </Button>
            }
          />
        ) : (
          <Button type="submit" isLoading={isLoading} disabled={isLoading}>
            {formType === "create" ? "Create" : "Update"}
          </Button>
        )}
      </div>
    </div>
  );
}
