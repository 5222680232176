import React, { useState, useEffect } from "react";
import styled from "styled-components";
import clsx from "clsx";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { PencilAltIcon, FolderOpenIcon } from "@heroicons/react/solid";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { Container } from "../../utils";

import { PageHeading, Badge, Spinner } from "../../common";
import { JobsApi } from "../../api";
import { CreateJob } from "../../components/Jobs";
import supabase from "../../api/supabase";

export { JobDetails } from "./Details";
export { EditJob } from "./Edit";

export const JobsMain = () => {
  const user = supabase.auth.user();

  const jobQuery = JobsApi.useJobs();

  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue] = useState("");
  const jobsConditionsDropdowns = [
    {
      label: "Equals",
      value: FilterMatchMode.EQUALS,
    },
    {
      label: "Not equals",
      value: FilterMatchMode.NOT_EQUALS,
    },
    {
      label: "Contains",
      value: FilterMatchMode.CONTAINS,
    },
    {
      label: "Not contains",
      value: FilterMatchMode.NOT_CONTAINS,
    },
    {
      label: "Ends with",
      value: FilterMatchMode.ENDS_WITH,
    },
    {
      label: "Starts with",
      value: FilterMatchMode.STARTS_WITH,
    },
  ];
  const dt = React.useRef(null);
  useEffect(async () => {
    initFilters();
  }, []);

  const formatDate = (value) =>
    value.toLocaleDateString("en-NZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const clearFilter = () => {
    initFilters();
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.EQUALS },
      status: { value: "Active", matchMode: FilterMatchMode.EQUALS },
      job_status: { value: null, matchMode: FilterMatchMode.EQUALS },
      on_hire: { value: null, matchMode: FilterMatchMode.EQUALS },
      hiab_required: { value: null, matchMode: FilterMatchMode.EQUALS },
      worksafe_notified: { value: "", matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const renderHeader = () => (
    <div className="-mb-12 -mt-8">
      <div className="flex items-center">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear Filters"
          className="p-button-outlined"
          onClick={clearFilter}
        />
        <span className="p-input-icon-left ml-2">
          {/* <i className="pi pi-search" /> */}
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange}
            placeholder="Search Jobs"
          />
        </span>
      </div>
      <div className="mt-4">
        <Button
          type="button"
          icon="pi pi-file"
          label="Export"
          onClick={() => exportCSV(false)}
          className="p-mr-2 p-button-outlined"
          data-pr-tooltip="CSV"
        />
      </div>
    </div>
  );

  const statusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Active", "Inactive"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );

  const jobStatusDropdowns = [
    "Pending Job Confirmation Doc",
    "Completed",
    "REQUEST",
    "Quote Sent",
    "Lost",
    "Scheduled",
    "Archived",
    "Approved",
    "Job Complete",
    "Admin Complete",
  ];

  const jobOnHireDropdowns = ["Yes", "No"];
  const workSafeDropdowns = [
    // { label: "All", value: null},
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" },
    { label: "N/A", value: "N/A" },
    { label: "Job Pending", value: "Job Pending" },
  ];

  const jobStatusFilterTemplate = (options) => (
    <>
      <Dropdown
        value={filters.job_status.matchMode}
        options={jobsConditionsDropdowns}
        onChange={(e) =>
          setFilters({ ...filters, job_status: { ...filters.visit_status, matchMode: e.value } })
        }
        // itemTemplate={(option) => option}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        defaultValue="In Progress"
      />
      <Dropdown
        value={options.value}
        options={jobStatusDropdowns}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={(option) => option}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    </>
  );

  const jobOnHireFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={jobOnHireDropdowns}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select"
      className="p-column-filter"
      showClear
    />
  );
  const jobHiabFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={jobOnHireDropdowns}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select"
      className="p-column-filter"
      showClear
    />
  );
  const worksafeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={workSafeDropdowns}
      optionLabel="label"
      optionValue="value"
      onChange={(e) => options.filterCallback(e.value, options.index)}
      placeholder="Select"
      className="p-column-filter"
      showClear
    />
  );

  const staffTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Employee", "Scaffolder", "Office", "Foreman", "Truck Driver", "Application"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );
  if (jobQuery.isLoading || !filters) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <Spinner size="lg" />
      </div>
    );
  }
  // const showStaff = (job_id) => {
  //   const data = staffData?.data?.find((e) => e?.job_id === job_id);
  //   if (!data) return "";

  //   return data?.staff?.staff_name;
  // };
  // Create an object of handover data for the job by querying the job_handover table and fitlering by the job_id
  // const handoverData = {

  // }

  const mappedJobData = jobQuery.data.map((job) => {
    const worksafe_notified = job?.job_handover?.[0]?.worksafe_notified || "N/A";
    const over_5m = job?.job_handover?.[0]?.over_5m;
    const newJob = { ...job, worksafe_notified: over_5m === "Yes" ? worksafe_notified : over_5m === "No" ? "N/A" : "Job Pending"};
    return newJob;
  });

  return (
    <div>
      <PageHeading title="Jobs" createBtn="Create Job" isEditable={false} setOpen={setOpen} />
      <Container>
        <div className="mx-auto mt-8">
          <DataTable
            ref={dt}
            value={mappedJobData}
            loading={jobQuery.isLoading}
            header={renderHeader()}
            paginator
            paginatorPosition="top|bottom|both"
            showGridlines
            rows={25}
            rowsPerPageOptions={[25, 50, 100]}
            dataKey="id"
            filters={filters}
            filterDisplay="menu"
            // stripedRows
            responsiveLayout="scroll"
            globalFilterFields={["id", "clients.client_name", "site", "job_status", "scafflog_number"]}
            emptyMessage="No jobs found."
            scrollHeight="600px"
          >
            {/* <Column field="job_id" header="Job" /> */}
            <Column
              header="Job # (Details)"
              field="job_id"
              // filterField="time_on"
              style={{ maxWidth: "8rem", textAlign: "center" }}
              body={(row) => (
                <Link
                  key={`details${row.id}`}
                  to={`jobs/${row.id}/details`}
                  className="flex items-center"
                >
                  <FolderOpenIcon className="h-4 w-4 text-gray-500 mr-2" />
                  <span className="hover:text-gray-800">#{row.id}</span>
                </Link>
              )}
            />
            <Column header="ScaffLog #" field="scafflog_number" />
            <Column header="Client" field="clients.client_name" />
            <Column header="Site Address" field="site" style={{ minWidth: "10rem" }} />
            {/* <Column
              header="Supervisor"
              field="id"
              style={{ minWidth: "10rem" }}
              body={(row) => showStaff(row.id)}
            /> */}

            <Column header="Start Date" field="start_date" style={{ minWidth: "10rem" }} />
            <Column
              header="Worksafe Notified"
              field="worksafe_notified"
              style={{ width: "8rem" }}
              filter
              filterElement={worksafeFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
              showFilterOperator={false}
              showAddButton={false}
              filterMatchMode={FilterMatchMode.EQUALS}
              showFilterMatchModes={false}
              filterField="worksafe_notified"
              body={(row) => {
                if (row?.worksafe_notified !== "") {
                  return (
                    <div className="align-middle text-center content-center pr-3">
                      <Badge
                        type={row?.worksafe_notified}
                        text={row?.worksafe_notified}
                      />
                    </div>
                  );
                }
                return row?.worksafe_notified; // return null or '' for blank cell
              }}
            />
            <Column
              field="hiab_required"
              header="Hiab Required"
              bodyClassName="p-text-center"
              body={(row) => <Badge type={row.hiab_required} text={row.hiab_required} />}
              style={{ width: "3rem", textAlign: "center" }}
              filter
              filterElement={jobHiabFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
              showFilterOperator={false}
              showAddButton={false}
              filterMatchMode={filters?.job_status?.matchMode}
              showFilterMatchModes={false}
            />
            <Column
              field="job_status"
              header="Job Status"
              bodyClassName="p-text-center"
              style={{ width: "10rem" }}
              filter
              filterElement={jobStatusFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
              showFilterOperator={false}
              showAddButton={false}
              filterMatchMode={filters?.job_status?.matchMode}
              showFilterMatchModes={false}
            />
            <Column
              field="on_hire"
              header="On Hire"
              bodyClassName="p-text-center"
              style={{ width: "10rem" }}
              filter
              filterElement={jobOnHireFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
            />
            <Column
              field="status"
              header="Status"
              bodyClassName="p-text-center"
              style={{ width: "10rem", textAlign: "center" }}
              body={(row) => <Badge type={row.status} text={row.status} />}
              showFilterOperator={false}
              showFilterMatchModes={false}
              filter
              filterElement={statusFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
            />

            <Column
              header="Edit"
              bodyClassName="p-text-center"
              style={{ width: "3rem" }}
              body={(row) => (
                <Link
                  to={{
                    pathname: `jobs/${row.id}/editJob`,
                    state: { background: location, name: "editJob" },
                  }}
                >
                  {user.email === "clifton@nss.co.nz" ||
                  user.email === "shaun@nss.co.nz" ||
                  user.email === "accounts@nss.co.nz" ||
                  user.email === "keith@techenabled.nz" ||
                  user.email === "samuel@soluntech.com" ? (
                    <PencilAltIcon className="text-gray-600 h-4 w-4" />
                  ) : (
                    ""
                  )}
                </Link>
              )}
            />
          </DataTable>
        </div>
      </Container>
      <CreateJob open={open} setOpen={setOpen} />
    </div>
  );
};
