import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchToolboxTalk(id) {
  if (id) {
    const { data, error } = await supabase
      .from("toolbox_talk")
      .select("*")
      .eq("id", id);
    if (error) {
      throw new Error(error.message);
    }
    return data[0];
  }
  return {};
}

export function useFetchToolboxTalk(id) {
  return useQuery({
    queryKey: ["toolbox_talk", id],
    queryFn: () => fetchToolboxTalk(id),
  });
}
