import { useState } from "react";
import moment from "moment";
import { DateSelect, Button, ConfirmationDialog, Checkbox } from "../../common";
import { useNotificationStore } from "../../store/notifications";

export const PublishVisits = ({ selectedVisit, setSelectedVisits }) => {
  const classOptions = [
    { value: "Client", label: "Client" },
    { value: "Staff", label: "Staff" },
  ];

  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
  const [options, setOptions] = useState([]);
  const approveInvoicesProcess = () => {
    if (!selectedVisit) {
      return;
    }

    fetch("https://metroscaff-server.herokuapp.com/api/publish-message", {
      method: "POST", // or 'PUT'
      body: JSON.stringify({
        type: "publish_visits",
        data: selectedVisit,
        options,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        const contentType = res.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return res.json();
        } 
          return res.text();
        
      })
      .then((response) => {
        let isSuccess = true;
        let content = "Message sent!";
        const heading = "Success!";
        if (response?.err && response.err.code !== "SUCCESS") {
          isSuccess = false;
          content = "Error!";
        }
        addNotification({
          isSuccess,
          heading,
          content,
        });
      })
      .catch((error) => {
        console.error(error); // Log the error
        addNotification({
          isSuccess: false,
          heading: "Error!",
          content: `Network Error With Backend.`,
        });
      });
    setCompleted(true);
    setSelectedVisits([]);
  };

  const setFieldValue = (id, val) => {
    setOptions(val);
  };

  return (
    <ConfirmationDialog
      isDone={completed}
      icon="info"
      title="Send Update"
      triggerButton={
        <Button
          label="Send Update"
          style={{ backgroundColor: "#0078d4", borderRadius: "4px" }}
          icon="submit"
          className="p-button-raised p-3 flex align-items-center justify-content-center"
        >
          Send Update
        </Button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            setIsLoading(true);
            approveInvoicesProcess();
            setIsLoading(false);
          }}
        >
          Send Update
        </Button>
      }
      declineButton={<Button variant="inverse">Cancel</Button>}
    >
      <div className="flex">
        <Checkbox options={classOptions} id="options" values={options} onChange={setFieldValue} />
      </div>
    </ConfirmationDialog>
  );
};
