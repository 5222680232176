import React, { useState } from "react";

import { SupportForm } from "../../components/Support";

export const EditSupport = (props) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <SupportForm open={open} setOpen={setOpen} formType="edit" />
    </>
  );
};
