import { classNames } from "../../utils";

export function Badge({ type, text }) {
  let styles = "";
  if (
    type === "Alert" ||
    type === "Rejected" ||
    type === "Issue" ||
    type === "High" ||
    type === "Signed Out" ||
    type === "No" ||
    type === "Red"
  )
    styles = "bg-red-100 text-red-800";
  if (
    type === "Inactive" ||
    type === "Pending" ||
    type === "Med" ||
    type === "Pending Close Of Visit" ||
    type === "Pending Prestart" ||
    type === "N/A" ||
    type === "Yellow"
  )
    styles = "bg-yellow-100 text-yellow-800";
  if (
    type === "Active" ||
    type === "Approved" ||
    type === "Operational" ||
    type === "Low" ||
    type === "Signed In" ||
    type === "Yes" ||
    type === "Completed" ||
    type === "Green"
  )
    styles = "bg-green-100 text-green-800";
  if (type === "Job Pending") styles = "bg-purple-100 text-purple-800";
  if (type === "Blue") styles = "bg-blue-100 text-blue-800";
  if (type === "Orange") styles = "bg-orange-100 text-orange-800";
  if (type === "Gray") styles = "bg-gray-100 text-gray-800";

  return (
    <>
      <span
        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${styles}`}
      >
        {text}
      </span>
    </>
  );
}
