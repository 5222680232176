import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchSupportTicket(id) {
  const { data, error } = await supabase.from("support_tickets").select("*").eq("id", id);
  if (error) {
    throw new Error(error.message);
  }
  return data[0];
}

export function useSupportTicket(id) {
  return useQuery("supportTicket", () => fetchSupportTicket(id));
}
