import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

async function createSupportTicket(supportTicket) {
  const { data, error } = await supabase.from("support_tickets").insert(supportTicket);

  if (error) {
    throw new Error(error.message);
  }

  await supabase
    .from("support_tickets")
    .update({ ticket_id: `MTS-${1000 + data[0].id}` })
    .match({ id: data[0].id });

  return data;
}

export function useCreateSupportTicket() {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((supportTicket) => createSupportTicket(supportTicket), {
    onSuccess: () => {
      queryClient.refetchQueries("supportTickets");
      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully created support ticket.`,
      });
    },
    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed creating support ticket",
        content: err?.message,
      });
    },
    mutationFn: createSupportTicket,
  });
}
