import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  messsage: {
    fontFamily: "Open Sans",
    fontSize: 8,
    marginBottom: 10,
  },
});

export const Greetings = ({ client }) => (
  <View style={styles.headerContainer}>
    <View>
      <Text style={styles.messsage}>Dear {client}</Text>
      <Text style={styles.messsage}>
        Thank you for giving us the opportunity in submitting this quotation for your consideration.
      </Text>
    </View>
  </View>
);
