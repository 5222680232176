import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import moment from "moment";
import { SideModal, Input, Dropdown, Address, Spinner, TextArea, DateSelect } from "../../common";
import { StaffApi, SupportApi } from "../../api";
import { CreateFile } from "./CreateFile";
import supabase from "../../api/supabase";

const typeOptions = [
  {
    value: "Urgent Bug Fix",
    label: "Urgent Bug Fix",
  },
  {
    value: "Non-Urgent Bug Fix",
    label: "Non-Urgent Bug Fix",
  },
  {
    value: "Change Request",
    label: "Change Request",
  },
  {
    value: "Variation",
    label: "Variation",
  },
  {
    value: "Query",
    label: "Query",
  },
];

const statusOptions = [
  {
    value: "Open",
    label: "Open",
  },
  {
    value: "In Progress",
    label: "In Progress",
  },
  {
    value: "More Info Required",
    label: "More Info Required",
  },
  {
    value: "Client Signoff",
    label: "Client Signoff",
  },
  {
    value: "Closed",
    label: "Closed",
  },
];

const typeToDays = {
  "Urgent Bug Fix": 3,
  "Non-Urgent Bug Fix": 7,
  "Change Request": 5,
  Variation: 10,
};

export function SupportForm({ heading, open, setOpen, formType = "create", setClientId }) {
  const [supportTicket, setSupportTicket] = useState([]);

  const createSupportTicketMutation = SupportApi.useCreateSupportTicket();
  const updateSupportTicketMutation = SupportApi.useUpdateSupportTicket();

  const { user } = supabase.auth.currentSession;
  const { data: defaultUploaderData } = StaffApi.useStaffByEmail(user?.email);
  const staffData = StaffApi.useStaff();

  const history = useHistory();
  const { supportTicketId } = useParams();

  useEffect(() => {
    let isCurrent = true;

    if (!open && supportTicketId) {
      history.goBack();
    }

    if (supportTicketId) {
      SupportApi.fetchSupportTicket(supportTicketId).then((supportTicketData) => {
        if (isCurrent) setSupportTicket(supportTicketData);
      });
    }

    return () => {
      isCurrent = false;
    };
  }, [supportTicketId, open]);

  const renderStaffList = () => {
    if (staffData && staffData?.data?.length > 0) {
      return staffData.data
        .filter((staff) => staff.status === "Active")
        .map((staff) => ({
          label: staff.staff_name,
          value: staff.id,
        }));
    }
    return [];
  };

  if (formType === "edit" && !supportTicket.id)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );

  return (
    <div>
      <Formik
        initialValues={{
          type: supportTicket?.type || "",
          title: supportTicket?.title || "",
          description: supportTicket?.description || "",
          notes: supportTicket?.notes || "",
          file_1: supportTicket?.file_1 || "",
          file_2: supportTicket?.file_2 || "",
          link: supportTicket?.link || "",
          status: supportTicket?.status || "Open",
          uploaded_by_id:
            supportTicket?.uploaded_by_id ||
            (defaultUploaderData?.id ? defaultUploaderData?.id : null),
          due_date: supportTicket?.due_date || "",
          created_at: supportTicket?.created_at
            ? moment(supportTicket?.created_at).format("DD/MM/YYYY")
            : moment().format("DD/MM/YYYY"),
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const {
            type,
            title,
            description,
            notes,
            file_1,
            file_2,
            link,
            status,
            uploaded_by_id,
            due_date,
          } = values;
          const supportTicketPayload = {
            type,
            title,
            description,
            notes,
            file_1,
            file_2,
            link,
            status,
            uploaded_by_id,
            due_date: moment(due_date).format("DD/MM/YYYY").toString(),
          };
          try {
            if (formType === "edit") {
              await updateSupportTicketMutation.mutateAsync({
                supportTicket: supportTicketPayload,
                supportTicketId: supportTicket?.id,
              });
            } else {
              await createSupportTicketMutation.mutateAsync(supportTicketPayload);
            }

            setOpen(false);
            resetForm();
          } catch (err) {
            console.log("ERROR UPDATING", err);
          }
        }}
        validate={(values) => {
          const errors = {};
          if (formType !== "edit" && !values.type) errors.type = "Type Is Required.";
          if (formType !== "edit" && !values.title) errors.title = "Title Is Required.";
          if (formType !== "edit" && !values.description)
            errors.description = "Description Is Required.";
          return errors;
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => {
          useEffect(() => {
            if (values.type && typeToDays[values.type] && formType === "create") {
              const daysToAdd = typeToDays[values.type];
              const createdAtDate = moment(values.created_at, "DD/MM/YYYY");
              const newDueDate = createdAtDate.add(daysToAdd, "days").format("DD/MM/YYYY");
              setFieldValue("due_date", newDueDate);
            }
          }, [values.type, values.created_at, setFieldValue]);

          return (
            <SideModal
              heading={heading}
              open={open}
              setOpen={setOpen}
              handleSubmit={handleSubmit}
              isLoading={isSubmitting}
              formType={formType}
            >
              <div>
                <div className="w-1/2">
                  <Dropdown
                    label="Type"
                    id="type"
                    options={typeOptions}
                    value={values.type}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.type}
                  />
                </div>
                <div className="w-1/2">
                  <Input
                    title="Title"
                    id="title"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.title}
                    error={errors.title}
                  />
                </div>
                <div className="w-full">
                  <TextArea
                    title="Description"
                    id="description"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.description}
                    error={errors.description}
                  />
                </div>
                <div className="w-full">
                  <TextArea
                    title="Notes (For ScaffM8 Staff Only)"
                    id="notes"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.notes}
                    error={errors.notes}
                  />
                </div>
                <div className="flex items-center">
                  <div className="w-1/2">
                    <h4 className="text-md font-normal leading-5 px-4">File 1</h4>
                    <CreateFile field="file_1" setFieldValue={setFieldValue} type="edit" />
                  </div>
                  <div className="w-1/2">
                    <h4 className="text-md font-normal leading-5 px-4">File 2</h4>
                    <CreateFile field="file_2" setFieldValue={setFieldValue} type="edit" />
                  </div>
                </div>
                <div className="w-full">
                  <Input
                    title="Link"
                    id="text"
                    type="link"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.link}
                  />
                </div>
                <div className="flex items-center">
                  <div className="w-1/2">
                    <Dropdown
                      label="Status"
                      id="status"
                      options={statusOptions}
                      value={values.status}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </div>
                  <div className="w-1/2">
                    <DateSelect
                      title="Due Date"
                      id="due_date"
                      value={values.due_date}
                      onChange={setFieldValue}
                    />
                  </div>
                </div>
                <div className="w-1/2">
                  <Dropdown
                    label="Uploaded By"
                    id="uploaded_by_id"
                    options={renderStaffList()}
                    value={values.uploaded_by_id}
                    isLoading={staffData.isFetching}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </div>
              </div>
            </SideModal>
          );
        }}
      </Formik>
    </div>
  );
}
