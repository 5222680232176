import React from "react";
import { Button } from "../../../common";
import { Container } from "../../../utils";

export const Rates = React.memo(({ data, setRates, user, handleChange }) => {
  const columns = ["Service", "Fee"];

  const updateRate = (id, name, value) => {
    setRates(data.map((rate) => (rate.id === id ? { ...rate, [name]: Number(value) } : rate)));
    handleChange(data.map((rate) => (rate.id === id ? { ...rate, [name]: Number(value) } : rate)));
  };

  const updateNameRate = (id, value) => {
    setRates(data.map((rate) => (rate.id === id ? { ...rate, service: value } : rate)));
    handleChange(data.map((rate) => (rate.id === id ? { ...rate, service: value } : rate)));
  };

  const newRate = () => {
    data.push({
      id: data.length + 1,
      service: "New Rate",
      type: "Both",
      erect_fee: 0,
      hire_fee: 0,
    });
    setRates(data);
    handleChange(data);
  };

  return (
    <>
      {data ? (
        <div className="w-full">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                {columns.map((column) => (
                  <>
                    <th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider">
                      {column}
                    </th>
                  </>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data
                .sort((a, b) =>
                  a.service.localeCompare(b.service, undefined, { sensitivity: "base" }),
                )
                .map((line) => (
                  <tr key={line.id}>
                    <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
                      <input
                        id={`rateErect${line?.id}`}
                        type="text"
                        defaultValue={line?.service}
                        className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none"
                        name="rateService"
                        onChange={(e) => updateNameRate(line?.id, e.target.value)}
                      />
                    </td>
                    <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
                      <input
                        id={`rateErect${line?.id}`}
                        type="number"
                        defaultValue={line?.erect_fee}
                        className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none"
                        name="rateErect"
                        onChange={(e) => updateRate(line?.id, "erect_fee", e.target.value)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <br />
          <Container>
            <Button onClick={() => newRate()}>Add new rate</Button>
          </Container>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
});
