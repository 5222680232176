import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";
import { createAppenateRecord } from "./createAppenateRecord"


async function createFile(payload) {
  const { data, error } = await supabase.from("files").insert(payload).select("*");

  if (data?.[0]?.job_id) {
    await createAppenateRecord([
      [
        data?.[0]?.id,
        data?.[0]?.job_id,
        data?.[0]?.file,
        data?.[0]?.file_description,
        data?.[0]?.notes,
        data?.[0]?.file_type,
        data?.[0]?.file_description,
      ],
    ]);
  }

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useCreateFile() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createFile(payload), {
    onSuccess: () => {
      queryClient.refetchQueries("files");
    },
  });
}
