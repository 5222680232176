import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { StaffApi } from "../../../api";

export function EditStaff({ setFieldValue, type = "create", values = [], error, addTypes }) {
  const {data: staffDataRaw, isLoading: staffLoading} = StaffApi.useStaff();
  const staffData = staffLoading ? [] : staffDataRaw.filter((staff) => staff.status === 'Active');
  const filteredStaff = useMemo(() => {
    if (addTypes?.length) {
      const newIds =
        staffData
          ?.filter((staff) => addTypes.includes(staff.type))
          ?.map((staff) => staff.id) || [];
      return [...values, ...newIds];
    }
    return values;
  }, [addTypes, values]);
  values = filteredStaff;
  const renderStaffList = () => {
    if (staffData && staffData?.length > 0) {
      return staffData.map((staff) => ({
        label: staff.staff_name,
        value: staff.id,
      }));
    }
    return [];
  };

  const displayStaff = React.useMemo(
    () => renderStaffList().filter((option) => values?.includes(option.value)),
    [values],
  );
  return (
    <div className="flex items-center">
      <div className="px-4 py-4 flex-grow">
        {" "}
        <label id="staff" htmlFor="staff" className="block mb-1 text-sm font-medium text-gray-700">
          Staff
        </label>
        <Select
          isMulti
          name="staff"
          id="staff"
          options={renderStaffList()}
          value={displayStaff}
          onChange={(value) => {
            const staffVals = value.map((item) => item.value);
            const staffLabels = value.map((item) => item.label);
            setFieldValue("staff_ids", staffVals);
            setFieldValue("staff_labels", staffLabels);
          }}
          isLoading={!renderStaffList().length}
          className="w-full basic-multi-select"
          classNamePrefix="select"
        />
        <div className="flex items-center">
          {error && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
