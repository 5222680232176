import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllToolboxTalk() {
  const { data, error } = await supabase
    .from("toolbox_talk")
    .select("*")
    .order("id", { ascending: false });
    // .select("*");
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useToolboxTalk() {
  return useQuery("toolbox_talk", () => fetchAllToolboxTalk());
}
