import { v4 as uuidv4 } from "uuid";

export function formatQuoteLines(lines, quoteId, type) {
  const outLines = lines.map((line) => ({
    ...((type === "edit") && { id: line.id || null }),
    quote_id: quoteId,
    zone: line.zone,
    zone_label: line.zoneLabel,
    type: line.type || "",
    description: line.description,
    quantity: line.quantity,
    length: line.lengthMeasurement || null,
    height: line.height || null,
    width: line.width || null,
    total_dimensions: line.totalDimensions || 0,
    weekly_duration: line.duration || 0,
    transport: line.transport || 0,
    erect_dismantle: line.dismantle,
    weekly_fee: line.hireFee || 0,
    totalDays: line.totalDays || 0,
    total: String(line.total) || "",
  }));
  return outLines;
}

export function formatImages(values, quoteId, type = "create", data) {
  const formatedImages = [];

  for (let i = 1; i <= 10; i++) {
    const photoField = `quote_photo_${i}`;
    const idField = `id`;
    const positionField = `quote_photo_${i}`;

    if (values[photoField]) {
      const image = {
        ...(type === "edit" && { [idField]: data[i - 1]?.id }),
        quote_id: quoteId,
        link: values[photoField].link,
        position: positionField,
        comment: values[photoField].comment,
      };

      formatedImages.push(image);
    }
  }

  return formatedImages;
}

export function formatQuotePortalLines(lines, quoteId) {
  return lines.map((item) => ({
    id: item.id,
    zone: String(item.zone).trim(),
    zoneLabel: item.zone_label,
    type: item.type,
    description: item.description,
    quantity: item.quantity || 1,
    lengthMeasurement: item.length,
    height: item.height,
    width: item.width,
    totalDimensions: item.total_dimensions || 0,
    duration: item.weekly_duration || 0,
    transport: item.transport || 0,
    dismantle: item.erect_dismantle,
    hireFee: item.weekly_fee || 0,
    totalDays: item.totalDays || 0,
    total: item.total || 0,
  }));
}

export function formatZones(zones, quoteId, type = "") {
  return zones.map((zone) => ({
    ...(type === "edit" && { id: zone.zone_id || null }),
    quote: quoteId,
    zone_id: zone.id,
    zone_label: zone.label,
  }));
}

export function formatAddons(addons, quoteId) {
  return addons.map((addon) => ({
    id: addon.id,
    quote: quoteId,
    type: addon.type || "Additional",
    description: addon.description,
    duration: Number(addon.duration) || null,
    hire_fee: Number(addon.hireFee) || null,
    fixed_charge: Number(addon.fixedCharge) || null,
    total: Number(addon.totalCost) || null,
  }));
}

export function formatRates(rates, quoteId, type = "create") {
  return rates.map((rate) => ({
    ...(type === "edit" && { id: rate.id || null }),
    quote_id: quoteId,
    service: rate.service,
    erect_fee: rate.erect_fee,
    hire_fee: rate.hire_fee,
    type: rate.type,
    gl_code: rate.gl_code,
  }));
}

export function formatPortalAddons(addons) {
  return addons.map((addon) => ({
    id: addon.id,
    type: addon.type,
    description: addon.description,
    duration: addon.duration,
    hireFee: addon.hire_fee,
    fixedCharge: addon.fixed_charge,
    totalCost: Number(addon.total),
  }));
}

export const tabs = [
  { name: "Phases", href: "#", id: 1 },
  { name: "Rates", href: "#", id: 2, needs_admin: false },
  { name: "Rates Edit", href: "#", id: 3, needs_admin: false },
];

export const zoneOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
];

export const quoteRates = [
  {
    id: uuidv4(),
    service: "Roof",
    erect: "95.00",
    hire: "12.00",
  },
  {
    id: uuidv4(),
    service: "Propping",
    erect: "22.00",
    hire: "2.00",
  },
  {
    service: "Edge Protection",
    erect: "28.00",
    hire: "0.70",
  },
  {
    service: "Shrinkwrap",
    erect: "10.00",
    hire: "0.00",
  },
  {
    service: "Geda 1200",
    erect: "320.00",
    hire: "5.00",
  },
];

// export const quoteTerms100 = `
// THIS QUOTE INCLUDES ERECTION AND DISMANTLE COSTS, TRANSPORTATION OF MATERIALS AND LABOUR AND WEEKLY SCAFFOLD INSPECTIONS.
// IF MODIFICATIONS ARE REQUIRED ONCE THE SCAFFOLD IS COMPLETED, FURTHER COSTS MAY INCURR, UNLESS MODIFICATIONS HAVE BEEN ALLOCATED FOR AND AGREED UPON IN THIS QUOTE.
// PAYMENT TERMS:
// *STRICTLY PAYMENT REQUIRED WITHIN 7 DAYS AFTER INVOICED FOR WORKS COMPLETED
// * 100% of Erect/Dismantle price paid upon Erection of Scaffolding Minimum of 1 weeks rental is charged, after the firsts weeks rental partial weeks are charged pro rata Rental of scaffolding charged at end of each calendar month or upon the dismantle of the scaffolding (which ever comes first)
// THANKS
// `;

// export const quoteTerms50 = `
// THIS QUOTE INCLUDES ERECTION AND DISMANTLE COSTS, TRANSPORTATION OF MATERIALS AND LABOUR AND WEEKLY SCAFFOLD INSPECTIONS.
// IF MODIFICATIONS ARE REQUIRED ONCE THE SCAFFOLD IS COMPLETED, FURTHER COSTS MAY INCURR, UNLESS MODIFICATIONS HAVE BEEN ALLOCATED FOR AND AGREED UPON IN THIS QUOTE.
// PAYMENT TERMS:
// STRICTLY PAYMENT REQUIRED WITHIN 7 DAYS AFTER INVOICED FOR WORKS COMPLETED
// 50% of Erect/Dismantle price paid upon Erection of Scaffolding
// 50% of Erect/Dismantle price paid upon Dismantle of Scaffolding Rental of scaffolding charged at end of each calendar month or upon the dismantle of the scaffolding (which ever comes first)
// THANKS

// `;

// export const quoteTerms10 = `
// THIS QUOTE INCLUDES ERECTION AND DISMANTLE COSTS, TRANSPORTATION OF MATERIALS AND LABOUR AND WEEKLY SCAFFOLD INSPECTIONS.
// IF MODIFICATIONS ARE REQUIRED ONCE THE SCAFFOLD IS COMPLETED, FURTHER COSTS MAY INCURR, UNLESS MODIFICATIONS HAVE BEEN ALLOCATED FOR AND AGREED UPON IN THIS QUOTE.
// PAYMENT TERMS:
// STRICTLY PAYMENT REQUIRED WITHIN 7 DAYS AFTER INVOICED FOR WORKS COMPLETED
// 10% of Erect/Dismantle price paid a minimum of 2 weeks prior to the Erection of Scaffolding
// 50% of Erect/Dismantle price paid upon Erection of Scaffolding
// 40% of Erect/Dismantle price paid upon Dismantle of Scaffolding Rental of scaffolding charged at end of each calendar month or upon the dismantle of the scaffolding (which ever comes first)
// THANKS

// `;
export const quoteTerms = `
Roof structures:
This quotation allows for plank protection to roofs, canopies, decks, verandas, or any other existing structures on which scaffolding needs to be erected. However, Vertex assumes these structures can support the scaffold and workload. It is on the sole discretion of the client to ensure the strength of the structures by seeking engineering advice at the client’s own costs prior to erection. 
Working on roofs:
Working on roofs can cause dents, cracks, scratches etc; we will take the utmost care to avoid this, but take no responsibility if any damage should occur, as roofs are generally not designed for being worked on.

Power lines: 
Government regulations prohibit the erection and use of any scaffolding closer than 4m to power lines without the power lines being sleeved or disconnected and without the consent from the property owner. Unless the client otherwise advises, with acceptance of this quote the property owner automatically has given consent for work to take place near the power lines. The minimum charge for an electrician to inspect the site and sleeve/de-sleeve the lines is $250 plus GST. In case the public power lines run along the property Wellington Electricity is in charge and further costs may apply including any traffic management etc. All costs will be an additional charge to this quote. All users of the scaffold must follow the instructions on the Electrical Safety Tag in the vicinity of the power lines.

Plants:
Any plants closer than 1m to the walls need to be trimmed by the client prior to commencement of scaffolding.

Satellite dishes / aerials:
Scaffolding may interfere with the TV reception and/or the dish/aerial is in the way of the scaffolding. This quote does not include relocation of any sat dish/aerial. However, Vertex is happy to assist in this matter.

Ties and fixing:
To secure the scaffolding wall ties need to be periodically screwed into the building structure and will be removed on dismantling. Any remedial work will be the client’s responsibility.

Garages, driveways and entrances:
Parts of the scaffolding will block the entrance, garage and/or driveway while the scaffolding is in place.

Neighbours:
Vertex needs to erect some scaffolding on the    side of the house onto the neighbour’s ground. It is the client’s responsibility to obtain permission from the neighbours to allow Vertex access to erect, dismantle and transport parts of scaffolding on their property prior to commencement of scaffolding erection.

Car parks and driveways:
Please note parts of the scaffolding will block the car parks and/or driveway while the scaffolding is in place.

Council permits:
A Corridor Access Request (CAR) is required when any work commences on public road assets such as footpaths, carriageways and berms. The CAR should be generated by the principal of the site and is to be set up via www.submitica.co.nz. Once the CAR is in place please liaise with Vertex who will then add the Road Usage License (RUL). For a service fee of $490 plus GST Vertex can set up the CAR on behalf of the principal. Please note: This quote does not include costs for any further requirements of the council to the scaffolding construction as per description above and/or any traffic management costs or any other costs.

Council parking permit:
A parking permit for parking the Vertex truck in front of the jobsite needs to be in place prior to commencement of the erection and dismantle of the job. This can be obtained by the client or by Vertex. For a service fee of $250 plus GST Vertex can organise the permit. Please note: This quote does not include any costs (e.g. traffic management, council fees) for receiving the parking permit.

Engineering:
Any costs of engineering for e.g. designs and/or certificates requested by the client or by any authorities are not included in this quote.

Foundation ties: 
To secure the scaffolding plus wrapping against uplifts wall ties need to be drilled into the concrete foundation of the house where possible and will be removed on dismantling. Any remedial work when ties are removed is the client’s responsibility.

Asbestos Management:
As there is asbestos present at the property the client must ensure that the job site including the scaffolding is safe for the Vertex staff to enter for scaffolding checks, alterations and dismantling. Vertex will request a copy of the asbestos management plan and a copy of the test results once asbestos removal is completed.

Neighbouring Property
Scaffolding will be built on the neighbour’s driveway on the left-hand side.

Neighbouring Businesses:
Scaffolding will be erected in front of neighbouring business.  It is the client’s responsibility to obtain permission from the businesses affected to allow Vertex access to erect and dismantle the scaffolding.
Please see the diagram below for an indication of the areas allowed for within this quote:

Shrink-wrap:
Weather protection will be provided to the walls by wrapping a structure approx. 1.5m above the gutter and approx. 1m into the roof to divert rainwater into the gutters. 
Please note: This price does not include the engineers report or the wrapping itself.


The following conditions apply to this job:

Our condition on acceptance of this quote is that we will require a 100% upfront payment of the EDC.  

EDC payment to be paid prior to commencement of erecting of scaffold. Balance to be paid 7 days after invoice has been sent upon removal of scaffolding.

Please confirm your acceptance of this Quote and enclosed Terms of Trade either online or via email.

`;

export function formatQuoteAdditionalConditions(additionalConditions, quoteId, type = "create") {
  return additionalConditions.map((condition) => ({
    ...(type === "edit" && { id: condition.id || null }),
    quote_id: quoteId,
    additional_condition_id: condition.additional_condition_id,
    checked: condition.checked,
    name: condition.name,
    title: condition.title,
    text: condition.text,
  }));
}
