import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Badge } from "../Badges";
import supabase from "../../api/supabase";
import { StaffApi } from "../../api";

export function XeroStatus() {
  const [xeroStatus, setXeroStatus] = useState(false);
  const [xeroUrl, setXeroUrl] = useState(false);
  const { user } = supabase.auth.currentSession;
  const { data: authenticatedStaffRecord } = StaffApi.useStaffByEmail(user?.email);

  const [fetchedInfo, setFetchedInfo] = useState(false);
  // Active
  useEffect(() => {
    if (!authenticatedStaffRecord) return;

    fetch(`https://metroscaff-server.herokuapp.com/xero-status?id=${authenticatedStaffRecord?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setXeroStatus(data?.xeroStatus);
        setXeroUrl(data?.xeroUrl);
        setFetchedInfo(true);
      });
  }, [authenticatedStaffRecord]);

  if (!fetchedInfo) {
    return (
      <div className="px-6 flex items-center">
        <Badge type="Pending" text="Xero Status: Loading" />
      </div>
    );
  }

  if (!xeroStatus) {
    return (
      <>
        <div className="px-6 flex items-center">
          <Badge type="Issue" text="Xero Status: Login Required" />
        </div>
        <br />
        <div className="px-6 flex items-center">
          <Button label="Login With Xero" onClick={() => window.location.replace(xeroUrl)} />
        </div>
      </>
    );
  }

  return (
    <div className="px-6 flex items-center">
      <Badge type="Active" text="Xero Status: Active" />
    </div>
  );
}
