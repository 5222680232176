import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Button, Input, TextArea, Dropdown, Address, Spinner } from "../../common";
import { useUpdateMessage, useFetchMessages } from "../../api/Messages";

export const ClientsOptions = [
  { value: "{{client_name}}", label: "Client Name" },
  { value: "{{date}}", label: "Date" },
  { value: "{{start_time}}", label: "Start Time" },
  { value: "{{visit_status}}", label: "Visit Status" },
  { value: "{{site}}", label: "Site" },
  { value: "{{notes}}", label: "Notes" },
  { value: "{{comments}}", label: "Comments" },
  { value: "{{type}}", label: "Type" },
];
export const StaffsOptions = [
  { value: "{{staff_name}}", label: "Staff Name" },
  { value: "{{team_leader_name}}", label: "Team Leader Name" },
  { value: "{{date}}", label: "Date" },
  { value: "{{start_time}}", label: "Start Time" },
  { value: "{{visit_status}}", label: "Visit Status" },
  { value: "{{site}}", label: "Site" },
  { value: "{{notes}}", label: "Notes" },
  { value: "{{type}}", label: "Type" },
  { value: "{{visit_staff_list}}", label: "Visit Staff Names" },
];
export const ToolboxOptions = [
  { value: "{{staff_name}}", label: "Staff Name" },
  { value: "{{toolbox_time}}", label: "Toolbox time" },
  { value: "{{toolbox_date}}", label: "Toolbox Date" },
  { value: "{{toolbox_description}}", label: "Toolbox Description" },

];
export const AdminPanelMain = () => {
  const messageQuery = useFetchMessages();
  const updateMessageMutation = useUpdateMessage();
  const [clientOptions, setClientOptions] = useState("");
  const [toolboxOptions, setToolboxOptions] = useState("");
  const [staffOptions, setStaffOptions] = useState("");

  if (messageQuery.isLoading)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  return (
    <Formik
      initialValues={{
        client_sms: messageQuery?.data[0]?.client_sms || "",
        staff_sms: messageQuery?.data[0]?.staff_sms || "",
        toolbox_sms: messageQuery?.data[0]?.toolbox_sms || "",
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const { client_sms, staff_sms, toolbox_sms } = values;
        const messagePayload = {
          client_sms,
          toolbox_sms,
          staff_sms,
        };

        try {
          const messageResult = await updateMessageMutation.mutateAsync({
            message: messagePayload,
            messageId: 1,
          });
        } catch (err) {
          console.log("ERROR", err);
        }
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <div className="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8 container">
          <div>
            <h1 className="font-semibold">Admin Panel</h1>
            <p>Manage your settings and preferences</p>
            <hr className="mb-5 mt-2" />
          </div>
          <div className="flex">
            <div className="w-3/12 border-r-2">
              <Button className="my-3">Message Templates</Button>
            </div>
            <div className="px-5 w-9/12 max-w-3xl">
              <h2>Message Templates</h2>
              <hr />
              <div className="flex">
                <TextArea
                  title="Toolbox Talks"
                  id="toolbox_sms"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.toolbox_sms}
                />
                <Dropdown
                  label="Toolbox Options"
                  value={toolboxOptions}
                  onChange={(opt, value) => {
                    setFieldValue("toolbox_sms", values.toolbox_sms + value);
                    setToolboxOptions(value);
                  }}
                  onBlur={setFieldTouched}
                  options={ToolboxOptions}
                />
              </div>
              <div className="flex">
                <TextArea
                  title="Visit SMS to Staff"
                  id="staff_sms"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.staff_sms}
                />
                <Dropdown
                  label="Staff Options"
                  value={staffOptions}
                  onChange={(opt, value) => {
                    setFieldValue("staff_sms", values.staff_sms + value);
                    setStaffOptions(value);
                  }}
                  onBlur={setFieldTouched}
                  options={StaffsOptions}
                />
              </div>

              <div className="flex">
                <TextArea
                  title="Visit SMS to Client"
                  id="client_sms"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.client_sms}
                />

                <Dropdown
                  label="Client Options"
                  value={clientOptions}
                  onChange={(opt, value) => {
                    setFieldValue("client_sms", values.client_sms + value);
                    setClientOptions(value);
                  }}
                  onBlur={setFieldTouched}
                  options={ClientsOptions}
                />
              </div>
              <div className="flex justify-end px-5">
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
