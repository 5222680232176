import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { PencilAltIcon, FolderOpenIcon } from "@heroicons/react/solid";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import moment from "moment";

import { Container } from "../../utils";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { PageHeading, Badge, Spinner } from "../../common";
import { SupportApi } from "../../api";
import { SupportForm } from "../../components/Support";

export { EditSupport } from "./Edit";

export const SupportMain = () => {
  const supportTicketsQuery = SupportApi.useSupportTickets();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue] = useState("");

  const dt = React.useRef(null);

  useEffect(() => {
    initFilters();
  }, []);

  const formatDate = (value) =>
    value.toLocaleDateString("en-NZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const clearFilter = () => {
    initFilters();
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: { value: null, matchMode: FilterMatchMode.EQUALS },
      type: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const renderHeader = () => (
    <div className="-mb-12 -mt-8">
      <div className="flex items-center">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear Filters"
          className="p-button-outlined"
          onClick={clearFilter}
        />
        <span className="p-input-icon-left ml-2">
          {/* <i className="pi pi-search" /> */}
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange}
            placeholder="Search Ticket"
          />
        </span>
      </div>
      <div className="mt-4">
        <Button
          type="button"
          icon="pi pi-file"
          label="Export"
          onClick={() => exportCSV(false)}
          className="p-mr-2 p-button-outlined"
          data-pr-tooltip="CSV"
        />
      </div>
    </div>
  );

  const dateBodyTemplate = (rowData, field) => formatDate(rowData[field]);
  const dateFilterTemplate = (options) => (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );
  const headerTemplate = (data) => (
    <td className="" colSpan="6">
      <span className="text-gray-900 font-bold">
        {data.job_id} - {data.jobs.site}
      </span>
    </td>
  );

  const statusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Open", "In Progress", "More Info Required", "Client Signoff", "Closed"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );

  const typeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Urgent Bug Fix", "Non-Urgent Bug Fix", "Change Request", "Variation", "Query"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Type"
      className="p-column-filter"
      showClear
    />
  );

  return (
    <div>
      <PageHeading title="Support" createBtn="Create Ticket" isEditable={false} setOpen={setOpen} />
      <Container>
        <div className="mx-auto mt-8">
          <DataTable
            ref={dt}
            value={supportTicketsQuery.data}
            loading={supportTicketsQuery.isLoading}
            header={renderHeader()}
            paginator
            paginatorPosition="top|bottom|both"
            showGridlines
            rows={100}
            rowsPerPageOptions={[25, 50, 100]}
            dataKey="id"
            filters={filters}
            filterDisplay="menu"
            // stripedRows
            responsiveLayout="scroll"
            globalFilterFields={["ticket_id", "type", "title", "description", "notes", "status"]}
            emptyMessage="No support tickets found."
            scrollHeight="600px"
          >
            <Column
              header="Ticket ID"
              field="ticket_id"
              style={{ maxWidth: "10rem", textAlign: "center" }}
            />
            <Column
              header="Type"
              field="type"
              filter
              filterElement={typeFilterTemplate}
              filterMenuStyle={{ width: "12rem" }}
            />
            <Column header="Title" field="title" style={{ minWidth: "10rem" }} />
            <Column header="Description" field="description" style={{ width: "20rem" }} />
            <Column header="ScaffM8 Notes" field="notes" style={{ width: "20rem" }} />
            <Column
              header="File 1"
              field="file_1"
              style={{ minWidth: "10rem" }}
              body={(row) => {
                if (row.file_1) {
                  return (
                    <a href={row.file_1} target="_blank" rel="noreferrer" className="text-blue-400">
                      File Link
                    </a>
                  );
                }
                return <>No File Link</>;
              }}
            />
            <Column
              header="File 2"
              field="file_2"
              style={{ minWidth: "10rem" }}
              body={(row) => {
                if (row.file_2) {
                  return (
                    <a href={row.file_2} target="_blank" rel="noreferrer" className="text-blue-400">
                      File Link
                    </a>
                  );
                }
                return <>No File Link</>;
              }}
            />
            <Column
              header="Link"
              field="link"
              style={{ minWidth: "10rem" }}
              body={(row) => {
                if (row.link) {
                  return (
                    <a href={row.link} target="_blank" rel="noreferrer" className="text-blue-400">
                      Link
                    </a>
                  );
                }
                return <>No Link</>;
              }}
            />
            <Column
              header="Status"
              field="status"
              bodyClassName="p-text-center"
              style={{ width: "10rem", textAlign: "center" }}
              body={(row) => {
                let color;
                switch (row.status) {
                  case "Open":
                    color = "Red";
                    break;
                  case "More Info Required":
                    color = "Blue";
                    break;
                  case "Client Signoff":
                    color = "Yellow";
                    break;
                  case "Closed":
                    color = "Green";
                    break;
                  case "In Progress":
                    color = "Orange";
                    break;
                  default:
                    color = "Gray";
                }
                return <Badge type={color} text={String(row.status)} />;
              }}
              filter
              filterElement={statusFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
            />
            <Column header="Uploaded By" field="staff.staff_name" style={{ minWidth: "10rem" }} />
            <Column
              header="Created At"
              field="created_at"
              style={{ minWidth: "10rem" }}
              body={(row) => <>{moment(row.created_at).format("DD/MM/YYYY")}</>}
            />
            <Column header="Due Date" field="due_date" style={{ minWidth: "10rem" }} />
            <Column
              header="Edit"
              bodyClassName="p-text-center"
              style={{ width: "3rem" }}
              body={(row) => (
                <Link
                  to={{
                    pathname: `support/${row.id}/editSupportTicket`,
                    state: { background: location, name: "editSupportTicket" },
                  }}
                >
                  <PencilAltIcon className="text-gray-600 h-4 w-4" />
                </Link>
              )}
            />
          </DataTable>
        </div>
      </Container>
      <SupportForm heading="Create Ticket" open={open} setOpen={setOpen} />
    </div>
  );
};
