import React, { useState, useEffect } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { useHistory } from "react-router-dom";
import { PlusCircleIcon } from "@heroicons/react/solid";
// import "./styles.module.css";
import EventView from "./EventContent";
import { StyledWrapper } from "./styles/Scheduler";
import { gridViews } from "./static";
import { Button, Spinner } from "../../common";
import { EditVisit } from "../Visits/EditVisit";
import { CreateToolboxTalk } from "./CreateToolboxTalk";
import { useToolboxTalk } from "../../api/ToolboxTalk/getAllToolboxTalk";
import { useUpdateToolboxTalk } from "../../api/ToolboxTalk/updateToolboxTalk";

// Main Component
const Scheduler = () => {
  const [toolboxID, setToolboxID] = useState(0);
  const [open, setOpen] = useState(false);
  const updateUpdateToolboxTalk = useUpdateToolboxTalk();
  const { data, isLoading } = useToolboxTalk();
  const [allowDrop, setAllowDrop] = useState(true);

  const eventContent = (view) => <EventView event={view.event} />;
  const formatResources = () => {
    if (data) {
      return data.map((toolbox) => ({
        id: toolbox.id,
        date: moment(toolbox.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        time: toolbox.time,
        description: toolbox.description,
        staff: toolbox.staff?.staff_name,
      }));
    }
    return [];
  };
  const onEventClick = ({ event }) => {
    const { publicId, extendedProps } = event._def;
    setToolboxID(publicId);
    setOpen(true);
  };
  const eventDrop = async ({ event }) => {
    const toolboxID = Number(event?.id);
    const newDate = moment(event?.start).format("DD/MM/YYYY");
    if (toolboxID) {
      try {
        const res = await updateUpdateToolboxTalk.mutateAsync({
          id: toolboxID,
          toolboxTalk: {
            date: newDate,
            id: toolboxID,
          },
        });
      } catch (err) {
        console.log("ERROR", err);
      }
    }
    setAllowDrop(false);
    setTimeout(() => {
      setAllowDrop(true);
    }, 2500);
  };
  const formatEvents = () => {
    if (data) {
      return data.map((toolbox) => ({
        id: toolbox.id,
        date: moment(toolbox.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
        date2: toolbox.date,
        time: toolbox.time,
        description: toolbox.description,
        staff: toolbox?.staff_labels?.join(", "),
      }));
    }
    return [];
  };

  if (isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!data) return null;
  const firstDay = moment().startOf("week");

  const headerToolbar = {
    left: "title",
    right: "prev,today,next",
  };

  console.log("toolboxIDtoolboxID", toolboxID, open);

  return (
    <StyledWrapper>
      <div className="flex justify-end my-6">
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            setOpen(true);
            setToolboxID(0);
          }}
          startIcon={<PlusCircleIcon className="w-4 h-4" />}
        >
          Add Toolbox Talk
        </Button>
      </div>
      <FullCalendar
        initialView="Month"
        resourceAreaWidth="10%"
        resourceLabelText="fdfgfgdg"
        resourceAreaHeaderContent="Jobs"
        resourceOrder="-title"
        filterResourcesWithEvents
        headerToolbar={{
          ...headerToolbar,
          center: "Month",
        }}
        views={{
          Month: {
            type: "dayGridMonth",
            slotLabelInterval: { days: 1 },
            weekends: true,
            eventContent,
          },
        }}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin]}
        resources={formatResources()}
        height="auto"
        /* editable */
        selectable
        dayMaxEvents
        firstDay={1}
        events={formatEvents()}
        eventClick={onEventClick}
        eventDrop={eventDrop}
        // select={select}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        editable={allowDrop}
        droppable={allowDrop}
      />
      {toolboxID && open ? (
        <CreateToolboxTalk
          id={toolboxID}
          open={open}
          setOpen={setOpen}
          formType="edit"
          popup
          setToolboxID={setToolboxID}
        />
      ) : !toolboxID && open ? (
        <CreateToolboxTalk
          id={toolboxID}
          open={open}
          setOpen={setOpen}
          formType="create"
          setToolboxID={setToolboxID}
        />
      ) : null}
    </StyledWrapper>
  );
};

export default Scheduler;

function calendarProp() {
  const headerToolbar = {
    left: "title",
    right: "prev,today,next",
  };
  return {
    initialView: "Month",
    resourceAreaWidth: "18%",
    headerToolbar: {
      ...headerToolbar,
      center: "Month",
    },
    views: {
      Day: {
        type: "resourceTimelineDay",
        buttonLabel: "Day",
        slotDuration: { days: 1 },
      },
      Week: {
        type: "resourceTimeline",
        slotDuration: { days: 1 },
        // slotLabelFormat: "D",
        // slotLabelFormat: ["dddd D", "ha"],
        weekends: true,
        duration: { days: 7 },
        firstDay: 1,
      },
      Month: {
        type: "dayGridMonth",
        // slotLabelFormat: "D",
      },
    },
  };
}
