import React, { useState, useEffect } from "react";

import { PDFViewer, Document, Page, Image, View, Text, StyleSheet } from "@react-pdf/renderer";

import TermsOfTrade1 from "../../../TermsOfTrade1.jpg";
import TermsOfTrade2 from "../../../TermsOfTrade2.jpg";
import TermsOfTrade3 from "../../../TermsOfTrade3.jpg";

import { useQuoteStore } from "./store";

import { Spinner } from "../../../common";
import { Table } from "./Table/Table";
import { Description } from "./Description";
import { Terms } from "./Terms";

import { quoteColumns, summaryCostColumns, additionalItemsColumns } from "./columns";
import { Heading, ClientInfo } from "./Heading";
import { Greetings } from "./Greetings";
import { PageFooter } from "./PageFooter";

export function Output({ quote }) {

  const descriptionLines = quote.description.split(/\r\n|\r|\n/).length;
  console.log(descriptionLines);

  return (

        <Document>
          <Page size="A4" style={styles.page}>
            <Heading quote={quote} />
            <Text style={styles.quoteText}>QUOTATION</Text>
            <ClientInfo quote={quote} />
            <Greetings client={quote?.client_contacts?.name} />
            <Description description={quote.description} />
            {descriptionLines > 20 && (
              <>
                <Text break />
                <Heading quote={quote} />
                <Text style={styles.quoteText}>QUOTATION</Text>
                <ClientInfo quote={quote} />
              </>
            )}
             <Text style={styles.subTextMargin}>
              Notes: All pricing excludes GST and Includes total labour to erect and dismantle
              scaffolds, transport to and from site. and excludes any alterations unless previously
              agreed, Quotes are valid for 3 months from date of quote, by accepting this quote you
              agree to abide by our terms and conditions
            </Text>
            <View>
              <Text style={styles.messageFooterSubTextMargin}>
                All platforms will be built to Worksafe standards
              </Text>
              {/*               <Text style={styles.subTextMargin}>
                Thank you very much for considering our services.
              </Text> */}
              <Text style={styles.messageFooterSubTextMargin}>Regards</Text>
              <Text style={styles.messageFooterSubTextMargin}>Paul Nolan</Text>
              <Text style={styles.directorHeading}>DIRECTOR</Text>
            </View>
            <View style={styles.termsContainer}>
              <Text style={styles.termsHeading}>VIEW TERMS AND CONDITIONS:</Text>
              <Text style={styles.link}>
                <a
                  href="https://www.metroscaff.co.nz/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.metroscaff.co.nz/terms-and-conditions
                </a>{" "}
              </Text>
            </View>
            <View break />
            <Heading quote={quote} />
            <Text style={styles.quoteText}>QUOTATION</Text>
            <ClientInfo quote={quote} />
            {quote?.quote_lines.length ? (
              <View wrap={false}>
                <Text style={styles.heading}>Line Items</Text>
                <Table invoice={quote.quote_lines} columns={quoteColumns} groupable />
              </View>
            ) : null}

            {quote?.quote_addons.length ? (
              <View wrap={false}>
                <Text style={styles.heading}>Additional Items</Text>
                <Table
                  type="Additional"
                  invoice={quote.quote_addons}
                  columns={additionalItemsColumns}
                />
              </View>
            ) : null}

            {/*             <Text style={styles.subTextBoldRed}>All prices exclude GST</Text>

            <Text style={styles.subTextBold}>
              *Hire charges start on the date on which the erection of the scaffolding is completed
              and continue until the date of dismantling of the scaffolding as advised by the client
              (off-hire date). Both dates will be charged as whole days.
            </Text> */}
            <PageFooter />
          </Page>

          {quote?.quote_additional_conditions.length ? (
            <Page size="A4" style={{ ...styles.page, paddingTop: 50 }}>
              <View>
                <Text style={styles.heading}>Additional Conditions</Text>

                {quote?.quote_additional_conditions.map((line, index) => {
                  if (line.name === "paymentCondition1") {
                    return (
                      <View key={index} style={{ marginTop: 5 }}>
                        <Text style={{ ...styles.subText, marginBottom: 5 }}>
                          The following condition apply to this job:
                        </Text>
                        <Text style={styles.subText}>
                          <Text style={styles.subTextBold}>{line?.title}</Text> {line?.text}
                        </Text>
                      </View>
                    );
                  }
                  return (
                    <View key={index} style={{ marginTop: 5 }}>
                      <Text style={styles.subText}>
                        <Text style={styles.subTextBold}>{line?.title}</Text> {line?.text}
                      </Text>
                    </View>
                  );
                })}
              </View>
              <PageFooter />
            </Page>
          ) : null}
        </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    // paddingTop: 10,
    paddingBottom: 40,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "left",
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 9,
    color: "#97B597",
    marginTop: 8,
  },
  directorHeading: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "left",
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 8,
    marginLeft: 4,
  },
  termsHeading: {
    width: 150,
    alignItems: "left",
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 9,
    color: "#97B597",
    marginTop: 8,
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headingContact: {
    textAlign: "right",
    marginTop: 20,
  },
  headingDisplay: {
    display: "flex",
    flexDirection: "row",
  },
  headingText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 7,
  },
  quoteText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 14,
    marginTop: 20,
  },
  subText: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 7,
    // fontWeight: "semibold",
  },
  subTextMargin: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 7,
    marginTop: 15,
    // fontWeight: "semibold",
  },
  messageFooterSubTextMargin: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    marginTop: 7.5,
    fontSize: 7,
    // fontWeight: "semibold",
  },
  subTextBold: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 7,
    fontWeight: "bold",
  },
  subTextBoldRed: {
    fontFamily: "Open Sans",
    color: "red",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 7,
    fontWeight: "bold",
    textAlign: "right",
  },
  logo: {
    objectFit: "contain",
    width: "20%",
    height: "auto",
    paddingTop: 20,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    bottom: 7,
    right: 0,
  },
  pageTermsOfTrade: {
    objectFit: "contain",
    width: "105%",
    height: "auto",
    marginLeft: "-20",
  },
  footerLogo: {
    objectFit: "contain",
    width: "110%",
    // height: "auto",
    marginRight: 0,
  },

  footerText: {
    fontSize: 6,
    paddingLeft: 10,
  },

  pageNumber: {
    // position: "absolute",
    marginTop: 10,
    marginRight: 17,
    // paddingLeft: 70,
    fontSize: 8,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: "center",
    color: "grey",
  },
  link: {
    textDecoration: "underline",
    marginLeft: -10,
    fontSize: 8,
  },
  termsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    marginLeft: 4,
  },
});
