import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import moment from "moment";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

function calcDaysBetween(completedDate, dateOnHire) {
  const daysLeftInMonth = completedDate ? moment(completedDate, "DD/MM/YYYY") : moment();
  const hiredDate = moment(dateOnHire, "DD/MM/YYYY");
  return daysLeftInMonth.diff(hiredDate, "days") + 1;
}

export async function updateHire({ hire, hireId }) {
  const days_on_hire = Number(calcDaysBetween(hire.completed_date, hire.date_on_hire))
  const { data, error } = await supabase
    .from("weekly_hire_invoices")
    .update({...hire, days_on_hire})
    .match({ id: hireId });

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateHire = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((hire) => updateHire(hire), {
    onSuccess: (data) => {
      queryClient.refetchQueries("weeklyHires");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated hire invoice.`,
      });
    },

    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed updating hire invoice",
        content: err?.message,
      });
    },
    mutationFn: updateHire,
  });
};
