import { CheckIcon } from "@heroicons/react/outline";
import axios from "axios";
import { useState } from "react";
import moment from "moment";
import id from "faker/lib/locales/id_ID";
import { Input, TextArea, Button, ConfirmationDialog, DateSelect } from "../../common";
import { QuotesApi, WeeklyHireApi, JobsApi, FilesApi } from "../../api";
import { formatImages } from "./utils";
import supabase from "../../api/supabase";
import { useFetchQuoteImages } from "../../api/Quotes";

export const ApproveQuote = ({ quoteId, status, quotePayload }) => {
  const user = supabase.auth.user();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    approvedBy: user?.user_metadata?.name || "",
    start_date: moment().format("DD/MM/YYYY"),
    clientApproved: "",
    description: "",
    status: "Approved",
  });
  const updateApproveStatus = QuotesApi.useUpdateQuoteStatus(quoteId, state);
  const createJobFromQuote = QuotesApi.useCreateJobFromQuote();
  const createJobTasksFromQuote = QuotesApi.useCreateJobTasksFromQuote();
  const createFileMutation = FilesApi.useCreateFile();
  const createHireInvoices = WeeklyHireApi.useCreateHire();
  const createEdInvoiceMutation = JobsApi.useCreateEdInvoice();
  const updateImagesMutation = QuotesApi.useUpdateImages();
  const { data: quotePhotosData } = useFetchQuoteImages(quoteId);

  const handleInputChange = (event) => {
    const { id, value } = event.target;

    setState({
      ...state,
      [id]: value,
    });
  };

  return (
    <ConfirmationDialog
      isDone={createJobTasksFromQuote.isSuccess}
      icon="info"
      title="Approve Quote"
      body="Are you sure you want to approve this quote? This action will create a job with a list of tasks."
      triggerButton={
        <button
          type="button"
          id={quoteId}
          className={
            status !== "Approved" && status !== "Rejected"
              ? `inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400`
              : "inline-flex items-center text-sm text-gray-200"
          }
          disabled={status === "Approved" || status === "Rejected"}
        >
          <CheckIcon
            className={
              status !== "Approved" && status !== "Rejected"
                ? "-ml-0.5 mr-2 h-4 w-4 text-green-400"
                : "-ml-0.5 mr-2 h-4 w-4 text-green-100"
            }
            aria-hidden="true"
          />
          Approve
        </button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            const { quote_num } = quotePayload;
            if (quotePayload.quote_type === "Variation" && quotePayload.variation_job_id) {
              setIsLoading(true);
              // Just create tasks
              const quoteLines = quotePayload?.quote_lines;
              const quoteAddons = quotePayload?.quote_addons;
              const formatTaskPayload = quoteLinesToJobTasks(
                quoteLines,
                quotePayload.variation_job_id,
                "Variation",
                quotePayload.id,
                quotePayload.PO_Number,
              );
              let taskCreated = [];
              try {
                taskCreated = await createJobTasksFromQuote.mutateAsync({ formatTaskPayload });
              } catch (err) {
                console.log("ERROR CREATING TASKS", err);
              }
              const formatInvoices = quoteLinesToWeeklyInvoices(
                quoteLines,
                quotePayload.variation_job_id,
                taskCreated,
              );
              try {
                await createHireInvoices.mutateAsync(formatInvoices);
              } catch (err) {
                console.log("ERROR CREATING HIRE INVOICES", err);
              }

              const formatEDInvoices = quoteLinesToEDInvoices(
                quoteLines,
                quotePayload.variation_job_id,
                taskCreated,
                quote_num,
                quotePayload.PO_Number,
              );

              try {
                await createEdInvoiceMutation.mutateAsync(formatEDInvoices);
              } catch (err) {
                console.log("ERROR CREATING ED INVOICES", err);
              }

              try {
                await updateApproveStatus.mutateAsync();
              } catch (err) {
                console.log("ERROR UPDATING STATUS", err);
              }

              const formatedinvoices = quoteAddonsToEdInvoices(
                quoteAddons,
                quotePayload.variation_job_id,
                quotePayload.PO_Number,
              );

              try {
                await createEdInvoiceMutation.mutateAsync(formatedinvoices);
              } catch (err) {
                console.log("ERROR CREATING ED INVOICES", err);
              }
              setIsLoading(false);
            } else {
              setIsLoading(true);
              const fields = [
                [quotePayload.street_2],
                [quotePayload.suburb],
                [quotePayload.city],
                [quotePayload.post_code],
              ];
              const addressFormat = fields
                .map((part) => part.filter(Boolean).join(" "))
                .filter((str) => str.length)
                .join(", ");

              let latitude;
              let longitude;

              try {
                const response = await axios.get(
                  `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    addressFormat,
                  )}&key=AIzaSyBCacgb9m5pkr9vn-h2xXqB9aSqHQsgGl4`,
                );

                const { lat, lng } = response.data.results[0].geometry.location;
                latitude = lat;
                longitude = lng;
              } catch (error) {
                console.error("Error converting address to coordinates:", error);
              }
              const startDate = moment(state.start_date, "DD/MM/YYYY")
              const jobPayload = {
                id: quoteId,
                client_id: quotePayload?.client,
                site: addressFormat || "",
                quote_id: quoteId || null,
                start_date: startDate ? startDate.format("DD/MM/YYYY") : '',
                end_date: startDate ? startDate.add(3, "M").format("DD/MM/YYYY"): '',
                job_status: "Pending Job Confirmation Doc",
                status: "Active",
                clientType: quotePayload?.clientType,
                branding: quotePayload?.branding,
                lat: latitude,
                long: longitude,
                quote_num: quotePayload?.quote_num || "",
                po_number: quotePayload?.PO_Number || "",
                scafflog_number: quotePayload?.scafflog_number || "",
              };
              try {
                await updateApproveStatus.mutateAsync();
                const createdJob = await createJobFromQuote.mutateAsync(jobPayload);

                console.log('blah')
                const jobFiles = quotePhotosData.map((image, index) => ({
                  file_type: "Quote Attachment",
                  file_description: `Quote Attachment ${index}`,
                  file: image.link,
                  notes: image.comment,
                  job_id: createdJob.id
                }));
                console.log('jobFiles:', jobFiles); // Add this line
                // Promises of await createFileMutation.mutateAsync(jobFiles);
                const promises = jobFiles.map(async (file) => createFileMutation.mutateAsync(file));
                await Promise.all(promises);

                if (createdJob?.id) {
                  quotePayload?.quote_files?.map(async (image) => {
                     updateImagesMutation.mutate(
                      { ...image, job_id: createdJob.id },
                      {
                        onError: (error) => console.log("error", error),
                      },
                    );
                  });

                }

                const quoteLines = quotePayload?.quote_lines;
                const quoteAddons = quotePayload?.quote_addons;

                if (quoteLines?.length && createdJob?.id) {
                  const formatTaskPayload = quoteLinesToJobTasks(quoteLines, createdJob.id);

                  const taskCreated = await createJobTasksFromQuote.mutateAsync({
                    formatTaskPayload,
                    quote_num,
                  });

                  const formatInvoices = quoteLinesToWeeklyInvoices(
                    quoteLines,
                    createdJob.id,
                    taskCreated,
                  );

                  const formatEDInvoices = quoteLinesToEDInvoices(
                    quoteLines,
                    createdJob.id,
                    taskCreated,
                    quote_num,
                    quotePayload.PO_Number,
                  );



                  await createHireInvoices.mutateAsync(formatInvoices);

                  await createEdInvoiceMutation.mutateAsync(formatEDInvoices);
                }

                if (quoteAddons?.length && createdJob?.id) {
                  const formatedinvoices = quoteAddonsToEdInvoices(quoteAddons, createdJob?.id, quotePayload.PO_Number);

                  await createEdInvoiceMutation.mutateAsync(formatedinvoices);
                }

                setIsLoading(false);
              } catch (err) {
                console.log("ERROR ", err);
              }
            }

            // check client by name in xero with server
            fetch("https://metroscaff-server.herokuapp.com/api/checkcontact", {
              method: "POST",
              body: JSON.stringify({
                client: quotePayload?.clients?.client_name,
                branding: quotePayload?.branding,
              }),
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
              .catch((error) => console.error("Error:", error))
              .then((response) => console.log("Success:", response));
          }}
        >
          Approve
        </Button>
      }
      declineButton={<Button variant="inverse">Cancel</Button>}
    >
      <div className="flex">
        <div className="w-1/3">
          <Input
            title="Approved By"
            id="approvedBy"
            type="text"
            value={state.approvedBy}
            handleChange={handleInputChange}
          />
        </div>
        <div className="w-1/3">
          <Input
            title="Client Who Approved"
            id="clientApproved"
            type="text"
            value={state.clientApproved}
            handleChange={handleInputChange}
          />
        </div>
        <div className="w-1/3">
          <DateSelect
            title="Proposed Start Date"
            id="start_date"
            value={state.start_date}
            onChange={(id, value) => handleInputChange({ target: { id, value } })}
          />
        </div>
      </div>
      <TextArea
        title="Confirmation Text"
        id="description"
        type="text"
        value={state.description}
        handleChange={handleInputChange}
      />
    </ConfirmationDialog>
  );
};

function quoteLinesToJobTasks(lines, jobId, type, quoteId, PO_Number) {
  if (!type) {
    type = "New";
  }
  console.log("lines", lines);
  const linesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    linesResult.push({
      job_id: jobId,
      task_type: type,
      zone: lines[i].zone,
      zone_label: lines[i].zone_label,
      type: lines[i].type,
      description: lines[i].description,
      total_hours: quantity,
      variation_quote_id: quoteId,
      PO_Number,
    });
  }
  return linesResult;
}

function quoteAddonsToEdInvoices(quote_addons, job_id, po_number) {
  return quote_addons.map((row) => {
    const invoice = {
      zone: "",
      zone_label: "",
      type: row?.type,
      description: row?.description,
      erect_percent: 0,
      erect: 0,
      dismantle_percent: 0,
      PO_Number: po_number,
      dismantle: 0,
      ed_total: row?.total || 0,
      complete_percent: 100,
      invoiced: row?.total || 0,
      balance: row?.total || 0,
      last_invoice: 0,
      status: "Pending",
      job_id,
    };
    return invoice;
  });
}

function quoteLinesToWeeklyInvoices(lines, jobId, taskCreated) {
  console.log("lines", lines);
  let iteratorTask = 0;
  const linesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    linesResult.push({
      job_id: jobId,
      zone: lines[i].zone,
      zone_label: lines[i].zone_label,
      type: lines[i].type,
      description: lines[i].description,
      weekly_hire_rate: lines[i].weekly_fee,
      task_id: taskCreated[iteratorTask]?.id || 0,
    });
    iteratorTask++;
  }
  return linesResult;
}

function quoteLinesToEDInvoices(lines, jobId, taskCreated, quote_num, po_number) {
  console.log("lines", lines);
  let iteratorTask = 0;
  const linesResult = [];
  for (let i = 0; i < lines.length; i++) {
    const quantity = Number(lines[i].quantity) || 1;
    linesResult.push({
      job_id: jobId,
      task_id: taskCreated[iteratorTask]?.id || 0,
      PO_Number: po_number,
      Quote_Number: quote_num,
      zone: lines[i].zone,
      zone_label: lines[i].zone_label,
      type: lines[i].type,
      description: lines[i].description,
      erect_percent: 0,
      dismantle_percent: 0,
      complete_percent: 0,
      erect: lines[i].erect_dismantle * 0.7,
      dismantle: lines[i].erect_dismantle * 0.3,
      invoiced: 0,
      balance: 0,
      ed_total: lines[i].erect_dismantle,
    });
    iteratorTask++;
  }
  return linesResult;
}
