import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

export async function updateJob({ job, jobId }) {
  const { data, error } = await supabase
    .from("jobs")
    .update(job)
    .match({ id: jobId })
    .select("*,clients:client_id(*)");

  if (error) {
    throw new Error(error.message);
  }

  console.log('updateData', data);

  await updateAppenateJob(data);

  return data;
}

export const useUpdateJob = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((job) => updateJob(job), {
    onSuccess: (data) => {
      queryClient.refetchQueries("jobs");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated job.`,
      });
    },

    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed updating job",
        content: err?.message,
      });
    },
    mutationFn: updateJob,
  });
};

async function updateAppenateJob(jobs) {
  /*
    ROWS:
    id - job # - Client Id - Client Name - Client Contact # - Site - Job description - Status - Truck Driver - Supervisor -
  */

  const jobsPayload = [];

  jobs.map((job) =>
    jobsPayload.push([
      job.id,
      job.id,
      `${job.id} - ${job.clients.client_name} - ${job.site}`,
      job.clients.client_name || "",
      "",
      "",
      "",
      job.site || "",
      "",
      job.job_status === "Inactive" ? "Job Complete" : job.job_status || "",
      job?.truck_driver || "",
      job?.supervisor || "",
    ]),
  );

  return axios.post("https://metroscaff-server.herokuapp.com/api/data-sync", {
    id: "881ce4f1-618b-4bab-9c1d-af3500032c13",
    data: jobsPayload,
  });
}
