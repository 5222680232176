import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchAllEdInvoices() {
  const { data, error } = await supabase
    .from("edinvoices")
    .select("*, jobs:job_id(*, client:client_id(*)), job_tasks:task_id(*)")
    .order("job_id", { ascending: false });
  if (error) {
    console.log(error)
    throw new Error(error.message);
  }

  return data;
}

export function useEDInvoices() {
  return useQuery("EDInvoices", () => fetchAllEdInvoices());
}
