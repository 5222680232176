import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllSupportTickets() {
  const { data, error } = await supabase
    .from("support_tickets")
    .select("*, staff:uploaded_by_id( id, staff_name )")
    .order("id", { ascending: false });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export const useSupportTickets = () =>
  useQuery({
    queryKey: ["supportTickets"],
    queryFn: () => fetchAllSupportTickets(),
  });
