import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../../supabase";

export async function createJobTasksFromQuote({ formatTaskPayload }) {
  const { data, error } = await supabase.from("job_tasks").insert(formatTaskPayload);

  if (error) throw new Error(error.message);

  await createAppenateTask(data);

  return data;
}

export const useCreateJobTasksFromQuote = () =>
  useMutation((props) => createJobTasksFromQuote(props), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: createJobTasksFromQuote,
  });

async function createAppenateTask(jobs) {
  const tasksPayload = [];

  jobs.map((task) =>
    tasksPayload.push([
      task.id,
      task.job_id || "",
      task.zone || "",
      task.zone_label || "",
      task.type || "",
      task.description || "",
      task.complete || "",
    ]),
  );

  return axios.post("https://metroscaff-server.herokuapp.com/api/data-sync", {
    id: "091cc2e0-831e-4341-8108-af3500047302",
    data: tasksPayload,
  });
}
