import React, { useState, useEffect } from "react";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import moment from "moment";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button as ButtonReact } from "primereact/button";
import { PencilAltIcon, XIcon } from "@heroicons/react/solid";
import { Calendar } from "primereact/calendar";
import DatePicker from "react-datepicker";
import {
  PageHeading,
  Button,
  Spinner,
  Button as CustomButton,
  ConfirmationDialog,
} from "../../common";

import { Container, numberFormat } from "../../utils";

import "react-datepicker/dist/react-datepicker.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { WeeklyHireApi, JobsApi, InvoicesApi } from "../../api";
import { CreateEditEdInvoice } from "../../components/Jobs/CreateEditEdInvoice";
import { CreateEditWeeklyInvoice } from "../../components/WeeklyHire/EditHireInvoice";

moment.updateLocale("us", {
  workingWeekdays: [1, 2, 3, 4, 5],
});

export const InvoicesMain = () => {
  const hireQuery = WeeklyHireApi.useHiresApproved();
  const edinvoicesQuery = JobsApi.useEDInvoices();
  const EditInvoicesMutate = InvoicesApi.useUpdateInvoice();
  const [openToEditWeeklyInvoice, setopenToEditWeeklyInvoice] = useState(false);
  const [openToEditEdInvoice, setopenToEditEdInvoice] = useState(false);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue] = useState("");
  const [invoicesSelected, setInvoicesSelected] = useState(null);
  const [invoices, setInvoices] = useState();
  const [invoiceIDToEdit, setInvoiceIDToEdit] = useState(null);

  const dt = React.useRef(null);

  useEffect(() => {
    if (!hireQuery.isLoading && !edinvoicesQuery.isLoading) {
      const weeklyInvoices = hireQuery?.data?.map((row) => ({
        ...row,
        InvoiceType: "Weekly Hire",
      }));

      const edInvoices = edinvoicesQuery?.data
        ?.filter((e) => e.status === "Approved")
        .map((row) => ({
          ...row,
          InvoiceType: "ED",
          total: row.EDTotal,
        }));

      const invoicesFormated = [...weeklyInvoices, ...edInvoices].sort((a, b) => {
        if (a.job_id < b.job_id) {
          return 1;
        }
        if (a.job_id > b.job_id) {
          return -1;
        }
        return 0;
      });
      setInvoices(invoicesFormated);
    }
  }, [hireQuery.data, edinvoicesQuery.data]);

  useEffect(() => {
    initFilters();
  }, []);

  const clearFilter = () => {
    initFilters();
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      completed_date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      date_on_hire: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      on_hire: { value: null, matchMode: FilterMatchMode.EQUALS },
      // status: { value: "Active", matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const headerTemplate = (data) => (
    <td className="" colSpan="6">
      <span className="text-gray-900 font-bold">{`${data?.job_id } - ${data?.jobs?.site}`}</span>
    </td>
  );

  const statusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Yes", "No"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );

  const renderHeader = () => (
    <>
      <div className="flex items-center -ml-3">
        <ButtonReact
          type="button"
          icon="pi pi-filter-slash"
          label="Clear Filters"
          className="p-button-outlined"
          onClick={clearFilter}
        />
        <span className="p-input-icon-left ml-2">
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange}
            placeholder="Search Invoices"
          />
        </span>
      </div>
    </>
  );

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const dateFilterTemplate = (options) => (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="dd/mm/yy"
      placeholder="dd/mm/yyyy"
      mask="99/99/9999"
    />
  );

  if (!invoices) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <Container>
          <PageHeading title="Invoices" isEditable={false} />

          <div className="mx-auto mt-8">
            <DataTable
              scrollable={!openToEditWeeklyInvoice && !openToEditEdInvoice}
              ref={dt}
              value={invoices}
              dataKey="id"
              selectionMode="checkbox"
              groupRowsBy="job_id"
              rowGroupMode="subheader"
              header={renderHeader()}
              responsiveLayout="scroll"
              scrollHeight="600px"
              emptyMessage="No Invoices found."
              paginator
              paginatorPosition="top|bottom|both"
              showGridlines
              filters={filters}
              rows={100}
              rowsPerPageOptions={[25, 50, 100]}
              rowGroupHeaderTemplate={headerTemplate}
              selection={invoicesSelected}
              onSelectionChange={(e) => setInvoicesSelected(e.value)}
              globalFilterFields={["job_display", "type", "description", "InvoiceType", "jobs.site"]}
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
              <Column header="InvoiceType" field="InvoiceType" />
              <Column
                header="Zone"
                field="zone"
                bodyClassName="p-text-center"
                style={{ width: "10px" }}
              />
              <Column
                header="Zone Label"
                field="zone_label"
                bodyClassName="p-text-center"
                style={{ width: "10px" }}
              />
              <Column header="Type" field="type" />
              <Column header="Description" field="description" />
              <Column
                header="On Hire"
                field="on_hire"
                filter
                filterElement={statusFilterTemplate}
              />
              <Column header="% Complete" field="completed" />
              <Column header="Erect Cost" field="erect" />
              <Column header="Dismantle cost" field="dismantle" />
              <Column
                header="Date On Hire"
                field="date_on_hire"
                body={(data) => dateBodyTemplate(data, "date_on_hire")}
                dataType="date"
                filter
                filterElement={dateFilterTemplate}
              />
              <Column
                header="Date Completed"
                field="completed_date"
                body={(data) => dateBodyTemplate(data, "completed_date")}
                dataType="date"
                filter
                filterElement={dateFilterTemplate}
              />
              <Column
                header="Days on Hire"
                field="days_on_hire"
                body={(row) => {
                  if (row.date_on_hire) {
                    const days = calcDaysBetween(row.completed_date, row.date_on_hire);
                    return <span>{days}</span>;
                  }
                  if (row.completed_date) {
                    return row.days_on_hire;
                  }
                  return <></>;
                }}
              />
              <Column header="Weekly Hire Rate" field="weekly_hire_rate" />
              <Column
                header="Total"
                field="total"
                body={(row) => {
                  // If row total has already been calculated, use that value, otherwise calculate total.
                  if (row.completed_date && row.total) {
                    return numberFormat.format(row.total);
                  }

                  if(row.ed_total){
                    row.total = Number(row.ed_total);
                    return <span>{numberFormat.format(row.ed_total)}</span>
                  // eslint-disable-next-line no-else-return
                  }else if (row.date_on_hire && row.weekly_hire_rate && row.completed) {
                    /**
                     * Days left on hire:
                     * If a completed date has been set, use that date as the days diff between date on hire and completed
                     * Otherwise, get remaining days till end of month.
                     */
                    const daysOnHire = calcDaysBetween(row.completed_date, row.date_on_hire);
                    const totalCalc = calculateInvoiceTotal(
                      daysOnHire,
                      row.weekly_hire_rate,
                      row.completed,
                    );
                    row.total = Number(totalCalc);
                    return <span>{numberFormat.format(totalCalc)}</span>;
                  }
                  return <></>;
                }}
              />
            </DataTable>
          </div>
        </Container>
      </div>
      {openToEditEdInvoice ? (
        <CreateEditEdInvoice
          edinvoiceID={invoiceIDToEdit}
          open={openToEditEdInvoice}
          setOpen={setopenToEditEdInvoice}
        />
      ) : (
        <br />
      )}
      {openToEditWeeklyInvoice ? (
        <CreateEditWeeklyInvoice
          invoiceID={invoiceIDToEdit}
          open={openToEditWeeklyInvoice}
          setOpen={setopenToEditWeeklyInvoice}
        />
      ) : (
        <br />
      )}
    </>
  );
};

const dateBodyTemplate = (rowData, field) => formatDate(rowData[field]);
const formatDate = (value) => {
  // Check if value is date and return tolocaledatestring otherwise return
  if (Object.prototype.toString.call(value) === "[object Date]") {
    return value?.toLocaleDateString("en-NZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  }
  return value;
};


function calcDaysBetween(completedDate, dateOnHire) {
  const daysLeftInMonth = completedDate ? moment(completedDate, "DD/MM/YYYY") : moment();
  const hiredDate = moment(dateOnHire, "DD/MM/YYYY");
  return daysLeftInMonth.diff(hiredDate, "days") + 1;
}

function calculateInvoiceTotal(daysOnHire, hireRate, percentComplete) {
  /** ************
      Total Calculation:
      (Days_On_Hire * Daily_Fee) * (Percent_Complete / 100) >>>>> (13 * 100) * (50 / 100)
   
      Days_On_Hire = Days left in current month (From Date on Hire)
      Daily_Fee = “Weekly Hire Rate” / 5
      Percent_Complete = “% Complete”
   
    ************* */
  console.log("hireRate", hireRate);
  const dailyFee = (Number(hireRate) / 7).toFixed(2);
  const percent = Number(percentComplete);

  const totalCalc = daysOnHire * dailyFee * (percent / 100);
  console.log("CALC", daysOnHire, dailyFee, percent);
  return totalCalc.toFixed(2);
}
