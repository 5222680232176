/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { DocumentIcon } from "@heroicons/react/outline";
import moment from "moment";
import { Formik } from "formik";
import { Input, Dropdown, Spinner, DateSelect, ConfirmationDialog, Button } from "../../../common";

import { JobsApi, StaffApi, ClientContacts, ContactsApi, QuotesApi } from "../../../api";

import { useNotificationStore } from "../../../store/notifications";
import supabase from "../../../api/supabase";

const yesNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const yesNoNaOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
  { value: "N/A", label: "N/A" },
];

const assignToOptions = [
  { value: "Supervisor", label: "Supervisor" },
  { value: "Leading Hand", label: "Leading Hand" },
];

const invoiceTypeOptions = [
  { value: "Payment Claim", label: "Payment Claim" },
  { value: "Invoice", label: "Invoice" },
];

let staffOptions = [];
let staffOptionsByName = [];

export function HandoverForm({ jobId, handover, client_id }) {
  const [handoverData, setHandoverData] = useState(null);
  const [handoverDataLoading, setHandoverDataLoading] = useState(true);
  const { addNotification } = useNotificationStore();
  const [fileUpload, setFileUpload] = useState({
    file: "",
    status: "",
    url: "",
  });

  useEffect(() => {
    console.log("start Form Handover Document");
    StaffApi.fetchAllStaff().then((data) => {
      staffOptions = data.map((row) => ({ value: row?.id, label: row?.staff_name }));
      staffOptionsByName = data.map((row) => ({
        value: row?.staff_name,
        label: row?.staff_name,
      }));
    });
    if (handover.length) {
      setHandoverData(handover?.[0]);
      setHandoverDataLoading(false);
    }
  }, []);
  const user = supabase.auth.user();

  const createJobHandoverMutation = JobsApi.useCreateJobHandover();
  const updateJobHandoverMutation = JobsApi.useUpdateJobHandover();
  const updateJobStatusMutation = JobsApi.useUpdateJob();
  const createContactMutation = ContactsApi.useCreateContact();
  const { data: jobData, isLoading } = JobsApi.useFetchJob(jobId);
  const { data: quoteData, isLoading: quoteLoading } = QuotesApi.useFetchQuote(jobData?.quote_id);
  const { data: contactsData, isLoading: clientContactsLoading } =
    ContactsApi.useContacts(client_id);
  const [quotePowerLines, setQuotePowerLines] = useState(false);
  // const [quotePowerLinesData, setQuotePowerLinesData] = useState(false);
  const formikRef = React.useRef();

  useEffect(() => {
    if (jobData?.quote_id) {
      QuotesApi.getQuotePowerlines(jobData?.quote_id).then((data) => {
        if (data?.[0]?.checked) {
          setQuotePowerLines(true);
        }
      });
    }
  }, [jobData, handoverData, handoverDataLoading, contactsData, quoteData]);

  const handleFileChoosen = async (e) => {
    const file = e.target.files[0];

    const randomNum = Math.floor(Math.random() * 10000) + 1;
    const fileName = `${file.name}-${randomNum}`;
    const uploadFile = await supabase.storage
      .from("job-files")
      .upload(`worksafe/${fileName}`, file, {
        cacheControl: "3600",
        upsert: false,
      });
    const key = uploadFile?.data?.Key;
    if (key) {
      try {
        const uploadedFile = await supabase.storage
          .from("job-files")
          .getPublicUrl(`worksafe/${fileName}`);

        if (uploadedFile?.publicURL) {
          setFileUpload({
            ...fileUpload,
            file: fileName,
            status: "Success",
            url: uploadedFile?.publicURL,
          });

          addNotification({
            isSuccess: true,
            heading: "Success!",
            content: `Successfully uploaded File`,
          });
        }
      } catch (err) {
        addNotification({
          isSuccess: true,
          heading: "Error!",
          content: `Error uploading file!`,
        });
      }
    }
  };

  function isValidDate(date, format = "DD/MM/YYYY") {
    return moment(date, format).isValid();
  }

  const contactOptions =
    contactsData?.map((row) => ({
      value: row?.id,
      label: row?.name,
    })) || [];

  if (!user || isLoading || quoteLoading || clientContactsLoading)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );

  console.log(handoverData);

  return (
    <ConfirmationDialog
      isDone={createJobHandoverMutation.isSuccess || updateJobHandoverMutation.isSuccess}
      size="4xl"
      icon="info"
      title="Job Confirmation Form"
      body=""
      triggerButton={
        <Button
          size="sm"
          variant="inverse"
          className="mt-4"
          startIcon={<DocumentIcon className="w-4 h-4" />}
        >
          Job Confirmation Document
        </Button>
      }
      confirmButton={
        <Button
          type="submit"
          form="handoverForm"
          isLoading={
            createJobHandoverMutation.isLoading
              ? createJobHandoverMutation.isLoading
              : updateJobHandoverMutation.isLoading
          }
          disable={String(updateJobHandoverMutation.isLoading)}
        >
          {updateJobHandoverMutation.isLoading
            ? "Updating Document..."
            : createJobHandoverMutation.isLoading
            ? "Creating Document..."
            : handover.length
            ? "Update Document"
            : "Create Document"}
        </Button>
      }
      declineButton={
        <Button variant="danger" form="handoverForm">
          {handover.length ? "Cancel" : "Cancel"}
        </Button>
      }
    >
      <div>
        <Formik
          innerRef={formikRef}
          initialValues={{
            job_id: Number(jobId),
            start_date: isValidDate(jobData?.start_date)
              ? moment(jobData?.start_date, "DD/MM/YYYY").format("DD/MM/YYYY")
              : "",
            // Financials
            billing_address: handoverData?.billing_address || "No",
            credit_check: handoverData?.credit_check || "No",
            credit_check_who: handoverData?.credit_check_who || "",
            credit_check_when:
              handoverData?.credit_check_when !== "Invalid date" && handoverData?.credit_check_when
                ? moment(handoverData?.credit_check_when, "DD/MM/YYYY").format("DD/MM/YYYY")
                : "",
            invoiceType: handoverData?.invoiceType || "Invoice",
            over_5m: handoverData?.over_5m || "No",
            is_required_for_inspections: handoverData?.is_required_for_inspections || "No",
            worksafe_notified: handoverData?.worksafe_notified || "No",
            work_safe: handoverData?.work_safe || "",
            worksafe_uploaded: handoverData?.worksafe_uploaded || user?.user_metadata?.name,
            worksafe_uploaded_when: handoverData?.worksafe_uploaded_when
              ? moment(handoverData?.worksafe_uploaded_when).format("DD/MM/YYYY")
              : moment().format("DD/MM/YYYY"),
            worksafe_no: handoverData?.worksafe_no || "",
            sssp_added: handoverData?.sssp_added || "",
            swms_added: handoverData?.swms_added || "",
            hs_officer: handoverData?.hs_officer || "Metro H&S",
            hs_officer_phone: handoverData?.hs_officer_phone || "021 774 653",
            hs_officer_email: handoverData?.hs_officer_email || "admin@metroscaff.co.nz",
            operation_assignee: handoverData?.operation_assignee || "",
            site_forman: handoverData?.site_forman || quoteData?.client_contacts?.name || "",
            site_forman_email:
              handoverData?.site_forman_email || quoteData?.client_contacts?.email || "",
            site_forman_phone:
              handoverData?.site_forman_phone || quoteData?.client_contacts?.phone || "",
            site_forman2: handoverData?.site_forman2 || "",
            site_forman_email2: handoverData?.site_forman_email2 || "",
            site_forman_phone2: handoverData?.site_forman_phone2 || "",
            gear_shortages: handoverData?.gear_shortages || "",
            allowed_quote: handoverData?.allowed_quote || "",
            engaged_engineer: handoverData?.engaged_engineer || "",
            staff_availability: handoverData?.staff_availability || "",
            booked_shrinkwrappers: handoverData?.booked_shrinkwrappers || "",
            staff: handoverData?.staff || null,
            hs_officer_client: handoverData?.hs_officer_client || "",
            hs_officer_client_number: handoverData?.hs_officer_client_number || "",
            hs_officer_client_email: handoverData?.hs_officer_client_email || "",
            power_line_capping: handoverData?.power_line_capping || quotePowerLines ? "Yes" : "No",
            // hiab_required: handoverData?.hiab_required || "",
          }}
          onSubmit={async (values) => {
            const creditCheck = values.credit_check_when;
            const handoverPayload = {
              job_id: Number(jobId),
              // Financials
              billing_address: values.billing_address,
              credit_check: values.credit_check,
              credit_check_who: values.credit_check_who,
              credit_check_when: creditCheck ? moment(creditCheck).format("DD/MM/YYYY") : "",
              invoiceType: values.invoiceType,
              over_5m: values.over_5m,
              is_required_for_inspections: values.is_required_for_inspections,
              worksafe_notified: values.over_5m === "No" ? null : values.worksafe_notified,
              worksafe_no: values.work_safe_no === "No" ? null : values.worksafe_no,
              // HEALTH & SAFETY
              work_safe: values?.work_safe || fileUpload.url,
              worksafe_uploaded: fileUpload.url ? values.worksafe_uploaded : "",
              worksafe_uploaded_when: fileUpload.url ? values.worksafe_uploaded_when : "",
              sssp_added: values.sssp_added,
              swms_added: values.swms_added,
              hs_officer: values.hs_officer,
              hs_officer_phone: values.hs_officer_phone,
              hs_officer_email: values.hs_officer_email,
              hs_officer_client: values.hs_officer_client,
              hs_officer_client_number: values.hs_officer_client_number,
              hs_officer_client_email: values.hs_officer_client_email,

              // OPERATIONS
              operation_assignee: values.operation_assignee, // staffOptions.find(e => e.value === values.operation_assignee)?.label,
              site_forman: values.site_forman,
              site_forman_email: values.site_forman_email,
              site_forman_phone: values.site_forman_phone,
              site_forman2: values.site_forman2,
              site_forman_email2: values.site_forman_email2,
              site_forman_phone2: values.site_forman_phone2,
              gear_shortages: values.gear_shortages,
              allowed_quote: values.allowed_quote,
              engaged_engineer: values.engaged_engineer,
              staff_availability: values.staff_availability,
              booked_shrinkwrappers: values.booked_shrinkwrappers,
              staff: values.staff,
              power_line_capping: values.power_line_capping,
              // hiab_required: values.hiab_required,
            };
            if (!handoverData) {
              await createJobHandoverMutation.mutateAsync(handoverPayload);
            } else {
              await updateJobHandoverMutation.mutateAsync({
                payload: handoverPayload,
                handoverId: handoverData.id,
              });
            }

            const jobPayload = {
              job: {
                job_status: "In Progress",
                start_date: isValidDate(values?.start_date)
                  ? moment(values?.start_date, "DD/MM/YYYY").format("DD/MM/YYYY")
                  : "",
                // hiab_required: values.hiab_required,
              },
              jobId,
            };
            await updateJobStatusMutation.mutateAsync(jobPayload);

            // check if contact exist

            const clientContacts = await ContactsApi.fetchAllContacts(client_id);
            const contactFound1 = clientContacts.find(
              (e) => e.email === handoverPayload.site_forman_email,
            );
            const contactFound2 = clientContacts.find(
              (e) => e.email === handoverPayload.site_forman_email2,
            );

            if (contactFound1 === undefined) {
              await createContactMutation.mutateAsync({
                name: handoverPayload.site_forman,
                email: handoverPayload.site_forman_email,
                phone: handoverPayload.site_forman_phone,
                client_id,
                status: "Active",
              });
            }
            if (contactFound2 === undefined) {
              await createContactMutation.mutateAsync({
                name: handoverPayload.site_forman2,
                email: handoverPayload.site_forman_email2,
                phone: handoverPayload.site_forman_phone2,
                client_id,
                status: "Active",
              });
            }
          }}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} className="w-full" id="handoverForm">
              {/** ****************************************
               *
               * FINANCIALS
               *
               **************************************** */}
              <div>
                <h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4">FINANCIALS</h2>
                <div className="border-b" />
                <div className="flex items-center">
                  <Dropdown
                    label="Billing Address Added?"
                    id="billing_address"
                    value={values.billing_address}
                    error={errors.billing_address}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                  <Dropdown
                    label="Credit Check Completed?"
                    id="credit_check"
                    value={values.credit_check}
                    error={errors.credit_check}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                </div>
                <Dropdown
                  label="Invoice Type"
                  id="invoiceType"
                  value={values.invoiceType}
                  error={errors.invoiceType}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={invoiceTypeOptions}
                />
              </div>

              {values.credit_check === "Yes" && (
                <div className="flex items-center">
                  <Input
                    title="By Who?"
                    id="credit_check_who"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.credit_check_who}
                    error={errors.credit_check_who}
                  />
                  <DateSelect
                    title="When?"
                    id="credit_check_when"
                    value={values.credit_check_when}
                    error={errors.credit_check_when}
                    onChange={setFieldValue}
                  />
                </div>
              )}

              {/** ****************************************
               *
               * HEALTH & SAFETY
               *
               **************************************** */}
              <h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4">
                Health & Safety
              </h2>
              <div className="border-b" />

              <div className="flex items-center">
                <Input
                  title="H&S Officer"
                  id="hs_officer"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.hs_officer}
                  error={errors.hs_officer}
                />
                <Input
                  title="H&S Officer Phone #"
                  id="hs_officer_phone"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.hs_officer_phone}
                  error={errors.hs_officer_phone}
                />
                <Input
                  title="H&S Officer Email"
                  id="hs_officer_email"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.hs_officer_email}
                  error={errors.hs_officer_email}
                />
              </div>
              <div className="flex w-1/3">
                <Dropdown
                  label="Search Client H&S"
                  id="contactSearchHS"
                  options={contactOptions}
                  error={errors.contactSearchHS}
                  value={values.contactSearchHS}
                  placeholder="Search Contact"
                  onChange={(field, value) => {
                    setFieldValue(field, value);
                    const selectedContact = contactsData.find((e) => e.id === value);
                    if (selectedContact) {
                      setFieldValue("hs_officer_client", selectedContact?.name);
                      setFieldValue("hs_officer_client_number", selectedContact?.phone);
                      setFieldValue("hs_officer_client_email", selectedContact?.email);
                    }
                  }}
                  onBlur={setFieldTouched}
                />{" "}
              </div>

              <div className="flex items-centers">
                <Input
                  title="Client H&S Officer"
                  id="hs_officer_client"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.hs_officer_client}
                  error={errors.hs_officer_client}
                />
                <Input
                  title="Client H&S Officer Phone #"
                  id="hs_officer_client_number"
                  type="number"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.hs_officer_client_number}
                  error={errors.hs_officer_client_number}
                />

                <Input
                  title="Client H&S Officer Email"
                  id="hs_officer_client_email"
                  type="text"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.hs_officer_client_email}
                  error={errors.hs_officer_client_email}
                />
              </div>

              {/** ****************************************
               *
               * OPERATIONS
               *
               **************************************** */}
              <div>
                <h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4">Operations</h2>
                <div className="border-b" />
                <div className="flex items-center w-full">
                  <div className="w-1/3">
                    <Dropdown
                      label="Search Client Contact"
                      id="contactSearchClient"
                      options={contactOptions}
                      error={errors.contactSearchClient}
                      value={values.contactSearchClient}
                      placeholder="Search Contact"
                      onChange={(field, value) => {
                        setFieldValue(field, value);
                        const selectedContact = contactsData.find((e) => e.id === value);
                        setFieldValue("site_forman", selectedContact?.name);
                        setFieldValue("site_forman_phone", selectedContact?.phone);
                        setFieldValue("site_forman_email", selectedContact?.email);
                      }}
                      onBlur={setFieldTouched}
                    />
                  </div>
                  <div>
                    <DateSelect
                      title="Start Date"
                      id="start_date"
                      value={values.start_date}
                      error={errors.start_date}
                      onChange={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </div>
                </div>
                {/* <div className="flex items-center">
                  <Dropdown
                    label="Leading Hand"
                    id="operation_assignee"
                    value={values.operation_assignee}
                    error={errors.operation_assignee}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={staffOptionsByName}
                  />
                  <Dropdown
                    label="Supervisor"
                    id="staff"
                    value={values.staff}
                    error={errors.staff}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={staffOptions}
                  />
                </div> */}

                <div className="flex items-center">
                  <Input
                    title="Client Site Contact"
                    id="site_forman"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.site_forman}
                    error={errors.site_forman}
                  />
                  <Input
                    title="Client Site Contact Phone"
                    id="site_forman_phone"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.site_forman_phone}
                    error={errors.site_forman_phone}
                  />
                  <Input
                    title="Client Site Contact Email"
                    id="site_forman_email"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.site_forman_email}
                    error={errors.site_forman_email}
                  />
                </div>
                <div className="flex items-center ">
                  <Dropdown
                    label="Requires power-line capping or close approach?"
                    id="power_line_capping"
                    value={values.power_line_capping}
                    error={errors.power_line_capping}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                  <Dropdown
                    label="Will this require MetroScaff weekly inspections?"
                    id="is_required_for_inspections"
                    value={values.is_required_for_inspections}
                    error={errors.is_required_for_inspections}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                </div>

                <div className="flex items-center ">
                  <div className="w-1/2">
                    <Dropdown
                      label="Is this job over 5m?"
                      id="over_5m"
                      value={values.over_5m}
                      error={errors.over_5m}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      options={yesNoOptions}
                    />
                  </div>
                  <div className="w-1/2">
                    {values.over_5m === "Yes" && (
                      <Dropdown
                        label="Has worksafe been notified?"
                        id="worksafe_notified"
                        value={values.worksafe_notified}
                        error={errors.worksafe_notified}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        options={yesNoOptions}
                      />
                    )}
                  </div>
                  <div className="w-1/2">
                    {values.over_5m === "Yes" && values.worksafe_notified === "Yes" && (
                      <Input
                        title="Worksafe Number"
                        id="worksafe_no"
                        type="text"
                        value={values.worksafe_no}
                        error={errors.worksafe_no}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    )}
                  </div>
                </div>

                {/* <div className="flex items-center">
                  <Dropdown
                    label="Is Hiab required?"
                    id="hiab_required"
                    value={values.hiab_required}
                    error={errors.hiab_required}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                </div> */}

                {/* <div className="w-1/2">
                  <Input
                    title="Client Site Contact 2"
                    id="site_forman2"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.site_forman2}
                  // error={errors.site_forman}
                  />
                </div>
                <div className="flex items-center">
                  <Input
                    title="Client Site Contact Phone 2"
                    id="site_forman_phone2"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.site_forman_phone2}
                  // error={errors.site_forman_phone}
                  />
                  <Input
                    title="Client Site Contact Email 2"
                    id="site_forman_email2"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.site_forman_email2}
                  // error={errors.site_forman_email2}
                  />
                </div>

                <div className="flex items-center">
                  <Dropdown
                    label="Have you notified management of potential gear shortages to complete this job?"
                    id="gear_shortages"
                    value={values.gear_shortages}
                    error={errors.gear_shortages}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                  <Dropdown
                    label="Are you familiar with what has been allowed for in the quote?"
                    id="allowed_quote"
                    value={values.allowed_quote}
                    error={errors.allowed_quote}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                </div>

                <div className="flex items-center">
                  <Dropdown
                    label="Have you engaged with an Engineer if required on the Job?"
                    id="engaged_engineer"
                    value={values.engaged_engineer}
                    error={errors.engaged_engineer}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                  <Dropdown
                    label="Have you confirmed staff availability?"
                    id="staff_availability"
                    value={values.staff_availability}
                    error={errors.staff_availability}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />
                </div>

                <div className="flex w-1/2">
                  <Dropdown
                    label="Have you booked in shinkwrappers if required?"
                    id="booked_shrinkwrappers"
                    value={values.booked_shrinkwrappers}
                    error={errors.booked_shrinkwrappers}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={yesNoOptions}
                  />



                </div> */}
              </div>
            </form>
          )}
        </Formik>
      </div>
    </ConfirmationDialog>
  );
}
