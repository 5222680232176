import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

async function createToolboxTalk(toolbox_talk) {
  const { data, error } = await supabase.from("toolbox_talk").insert(toolbox_talk);

  if (error) {
    throw new Error(error.message);
  }


  return data;
}

export function useCreateToolboxTalk() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();

  return useMutation((toolbox_talk) => createToolboxTalk(toolbox_talk), {
    onSuccess: () => {
      queryClient.refetchQueries("toolbox_talk");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully created toolbox talk.`,
      });
    },
    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed creating toolbox talk",
        content: err?.message,
      });
    },
    mutationFn: createToolboxTalk,
  });
}
