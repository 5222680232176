import { useEffect, useState } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { classNames } from "../../../utils";

export function AdditionalConditionsQuote({
  id,
  sharedCheckBoxesState,
  labelInline = false,
  title,
  error,
}) {
  const [additioanConditions, setAdditioanConditions] = useState([]);
  let items = [];

  useEffect(() => {
    if (sharedCheckBoxesState) {
      setAdditioanConditions(sharedCheckBoxesState);
    }
  }, sharedCheckBoxesState);

  if (sharedCheckBoxesState) {
    items = sharedCheckBoxesState.filter((element) => element.checked === true);
  }

  return (
    <div
      className={classNames(
        labelInline
          ? "space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
          : "block",
        "w-full px-4 py-4 relative z-0",
      )}
    >
      <ScrollPanel style={{ width: "100%", height: "600px" }} className="additional-condition-bar">
        <div>
          <label htmlFor={id} className="block text-sm font-medium text-gray-700">
            {title}
          </label>
        </div>
        <div className="sm:col-span-2">
          {items.map((line, index) => (
            <div key={index} className="sm:col-span-2">
              {!line.name.includes("paymentCondition") && (
                <div>
                  <div className="sm:col-span-2">
                    <p className="mt-2 text-sm">
                      <b>{line.title}</b>
                    </p>
                  </div>

                  <div>
                    <p className="mt-2 text-sm">{line.text} </p>
                  </div>
                </div>
              )}
            </div>
          ))}
          <br />


          <br />

          <p className="mt-2 text-sm">The following condition apply to this job:</p>
          {items.map((line, index) => (
            <div key={index} className="sm:col-span-2">
              {(line.name.includes("paymentCondition")) && (
                <div>
                  <div className="sm:col-span-2">
                    <p className="mt-2 text-sm">
                      * <b>{line.title}</b>
                    </p>
                  </div>

                  <div>
                    <p className="mt-2 text-sm">{line.text} </p>
                  </div>
                </div>
              )}
            </div>
          ))}

          <br />


          <p className="mt-2 text-sm">
            Please confirm your acceptance of this Quote and enclosed Terms of Trade either online
            or via email.
          </p>
        </div>
      </ScrollPanel>

      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {error}
        </p>
      )}
    </div>
  );
}
