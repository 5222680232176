import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchStaffByEmail(email) {
  const { data, error } = await supabase.from("staff").select("*").eq("email", email);
  if (error) {
    throw new Error(error.message);
  }
  return data[0];
}

export function useStaffByEmail(email) {
  return useQuery("staffByEmail", () => fetchStaffByEmail(email));
}
