import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchHandoverByJobId(job_id) {
    const { data, error } = await supabase
      .from("job_handover")
      .select('*')
      .eq('job_id', job_id);
  
    if (error) {
      throw new Error(error.message);
    }
  
    return data;
  }

  export function useFetchHandoverByJobId(job_id) {
    return useQuery({
      queryKey: ["handover", job_id],
      queryFn: () => fetchHandoverByJobId(job_id),
    });
  }