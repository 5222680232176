import React from "react";

import { Container, HeadingContainer, StaffContainer, TaskContainer } from "./styledComponents";

const EventView = ({ event }) => {
  const color = {
    "": "#10B981",
    null: "#10B981",
    Install: "#10B981",
    Dismantle: "#ff1919",
    Adjustment: "#ff8c00",
    Remedial: "#ff8c00",
    Inactive: "#808080", // Add this line to set the color for Inactive status
  };
  const eventProp = event.extendedProps;
  console.log('Event status:', eventProp.status);

  const styles = () => ({
    color: "white",
    backgroundColor: eventProp.status === "Inactive" ? color.Inactive : color[eventProp.type],
  });

  return (
    <Container
      padding="0.5px"
      fontSize="0.8rem"
      color={styles().color}
      backgroundColor={styles().backgroundColor}
    >
      <HeadingContainer>{event.title}</HeadingContainer>
      <StaffContainer>
        <b>Team Leader:</b> {eventProp.teamLeader}
      </StaffContainer>
      <StaffContainer>
        <b>Staff:</b> {eventProp.staff}
      </StaffContainer>
      <StaffContainer>
        <b>Vehicles:</b> {eventProp.vehicles}
      </StaffContainer>
      <TaskContainer>
        <b>Type:</b> {eventProp.type}
      </TaskContainer>
      <TaskContainer>
        <b>Start Time:</b> {eventProp.timeStart}
      </TaskContainer>
      <TaskContainer>
        <b>Notes:</b> {eventProp.notes}
      </TaskContainer>
    </Container>
  );
};

export default EventView;
