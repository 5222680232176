import React, { useState, useEffect } from "react";
import moment from "moment";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { Container } from "../../utils";
import { VisitsApi, StaffApi } from "../../api";

export const TeamCompentencies = (jobId) => {
  const visitsQuery = VisitsApi.useGetVisitsByJob(jobId);

  const [loadingStaff, setLoadingStaff] = useState(false);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedTimesheets, setSelectedTimesheets] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue] = useState("");
  const [StaffQuery, setStaffQuery] = useState([]);

  const dt = React.useRef(null);

  useEffect(() => {
    initFilters();
  }, []);
  useEffect(() => {
    const Staffs = async () => {
      let array = [];
      if (visitsQuery.isSuccess) {
        visitsQuery?.data?.map((staff) => {
          if (staff.staff_ids) {
            array = [...staff?.staff_ids, ...array];
          }
          if (staff?.staff?.id) {
            array = [staff?.staff?.id, ...array];
          }
          return [];
        });
        array = array.filter((item, index) => array.indexOf(item) === index);
      }
      setLoadingStaff(true);
      const fetchPromises = array.map(async (id) => {
        const data = await StaffApi.fetchStaff(id);
        return data;
      });
      const results = await Promise.all(fetchPromises);
      const filteredResults = results.filter((item) => item !== undefined);
      setStaffQuery(filteredResults);
      setLoadingStaff(false);
      return filteredResults;
    };
    Staffs();
  }, [visitsQuery.isSuccess]);

  const formatDate = (value) =>
    value.toLocaleDateString("en-NZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const exportCSV = async (selectionOnly) => {
    setSelectedTimesheets(selectedTimesheets.filter((item) => item.status === "Active"));
    dt.current.exportCSV({ selectionOnly });
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      date: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.DATE_IS,
          },
        ],
        status: {
          operator: FilterOperator.AND,
          constraints: [{ value: "Active", matchMode: FilterMatchMode.EQUALS }],
        },
      },
      exported: { value: "No", matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const renderHeader = () => (
    <div className="-mb-12 -mt-10">
      <div className="flex items-center">
        <span className="p-input-icon-left ml-2">{/* <i className="pi pi-search" /> */}</span>
      </div>
      <div className="mt-4">
        <Button
          type="button"
          icon="pi pi-file"
          label="Export"
          onClick={() => {
            if (selectedTimesheets.length === 0) {
              alert("Select team compentencies using the selection checkbox to export");
            } else {
              exportCSV(true);
            }
          }}
          className="p-button-outlined p-mr-2"
          data-pr-tooltip="CSV"
        />
      </div>
    </div>
  );

  const statusActiveFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Active", "Inactive"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );

  return (
    <div>
      <Container>
        <div className="mx-auto mt-8">
          <DataTable
            ref={dt}
            value={StaffQuery}
            loading={loadingStaff}
            paginator
            paginatorPosition="top|bottom|both"
            showGridlines
            rows={100}
            rowsPerPageOptions={[25, 50, 100]}
            dataKey="id"
            responsiveLayout="scroll"
            globalFilterFields={["height_training_expiry", "cert_expiry_date", "cert_expiry_date"]}
            header={renderHeader()}
            emptyMessage="All Team Compentencies have been exported."
            rowGroupMode="subheader"
            // rowGroupFooterTemplate={footerTemplate}
            selection={selectedTimesheets}
            onSelectionChange={(e) => setSelectedTimesheets(e.value)}
            scrollHeight="600px"
            selectAll={selectedAll}
            onSelectAllChange={(e) => {
              if (e.checked) {
                setSelectedTimesheets(StaffQuery.filter((item) => item.status === "Active"));
                setSelectedAll(true);
              } else {
                setSelectedTimesheets([]);
                setSelectedAll(false);
              }
            }}
          >
            <Column
              className="bg-gray-300"
              selectionMode="multiple"
              headerStyle={{ width: "2rem" }}
            />
            <Column header="Staff Name" field="staff_name" />
            <Column
              header="Driver Licence"
              field="endorsement_expiry"
              style={{ minWidth: "10rem" }}
            />
            <Column
              header="Health & Safety Induction"
              field="expiry_date"
              style={{ minWidth: "10rem" }}
            />

            <Column
              field="passport_expiry"
              header="Building Construction Site Safe Passport"
              filterMenuStyle={{ width: "14rem" }}
              style={{ minWidth: "12rem" }}
            />
            <Column
              field="first_aid_expiry"
              header="First Aid Certificate"
              filterMenuStyle={{ width: "14rem" }}
              style={{ minWidth: "12rem" }}
            />
            <Column
              field="cert_expiry_date"
              header="Scaffolding Certificate of Competence"
              bodyClassName="p-text-center"
              style={{ minWidth: "8rem" }}
            />
            <Column
              field="height_training_expiry"
              header="Height Training"
              bodyClassName="p-text-center"
              style={{ minWidth: "4rem" }}
            />
            <Column
              field="status"
              header="Status"
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              filterElement={statusActiveFilterTemplate}
              filterMatchMode="equals"
              bodyClassName="p-text-center"
              style={{ minWidth: "4rem" }}
            />
          </DataTable>
        </div>
      </Container>
    </div>
  );
};
