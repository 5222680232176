import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllMessages() {
  const { data, error } = await supabase.from("messages").select("*").order('id', { ascending: true });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}
export const useFetchMessages = () => useQuery({
    queryKey: ["messages"],
    queryFn: () => fetchAllMessages(),
  });
