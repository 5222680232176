import React from "react";
import moment from "moment";
import { Text, Image, View, Font, StyleSheet } from "@react-pdf/renderer";

import logo from "../../../../logo.png";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

export const ClientInfo = ({ quote }) => {
  const fields = [[quote.street_1], [quote.street_2], [quote.city]];
  const addressFormat = fields
    .map((part) => part.filter(Boolean).join(" "))
    .filter((str) => str.length)
    .join(", ");

  const client = quote?.clients?.client_name;
  const contact = quote?.client_contacts?.name;
  const contactPhone = quote?.client_contacts?.phone;
  const contactEmail = quote?.client_contacts?.email;

  const street1 = quote?.street_1;
  const street2 = quote?.street_2;
  const city = quote?.city;
  const postCode = quote?.post_code;
  const address = addressFormat;
  return (
    <View style={styles.headingContainer}>
      <View style={styles.clientContainer}>
        <View style={styles.headingContact}>
          <View style={styles.clientHeadingDisplay}>
            <Text style={styles.headingText}>TO:</Text>
            <Text style={styles.clientSubText}>{client || " "}</Text>
            <Text style={styles.headingText}>CONTACT NAME:</Text>
            <Text style={styles.clientSubText}>{contact || " "}</Text>
          </View>
          <View style={styles.headingDisplay}>
            <Text style={styles.headingText}>SITE:</Text>
             {street1 ? <Text style={styles.subText}>{street1}</Text> : null}
              {/* {city ? <Text style={styles.subText}>{city}</Text> : null}
              {postCode ? <Text style={styles.subText}>{postCode}</Text> : null} */}
          </View>
        </View>
        <View style={styles.headingContact}>
          <View style={styles.headingDisplay}>
            <Text style={styles.headingText}>DATE:</Text>
            <Text style={styles.subText}>{moment(quote.created_at).format("D MMMM YYYY")}</Text>
          </View>
          {/*           <View style={styles.headingDisplay}>
            <Text style={styles.subText2}>Quotation Expires 30 days</Text>
          </View>
          <View style={styles.headingDisplay}>
            <Text style={styles.subText2}>from above date</Text>
          </View> */}
          <View style={styles.headingDisplay}>
            <Text style={styles.headingText}>QUOTE REF</Text>
            <Text style={styles.subText}>{quote?.quote_num}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: 20,
    width: "100%",
    // fontSize: 12,
  },
  clientContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 25,
  },
  addressContainer: {
    textAlign: "left",
    marginTop: 20,
    paddingRight: 8,
  },
  headingContact: {
    textAlign: "left",
    marginTop: -20,
    paddingRight: 19,
    marginRight: 18,
  },
  clientHeadingDisplay: {
    display: "flex",
  },
  headingDisplay: {
    display: "flex",
    flexDirection: "row",
  },
  headingText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 9,
    color: "#97B597",
  },
  subText: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 9,
    // fontWeight: "semibold",
  },
  clientSubText: {
    fontFamily: "Open Sans",
    fontSize: 9,
  },
  subText2: {
    fontFamily: "Open Sans",
    fontSize: 6,
    color: "red",
    // fontWeight: "semibold",
  },
  logo: {
    objectFit: "contain",
    width: "25%",
    height: "auto",
    paddingTop: 15,
  },
});
