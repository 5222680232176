import React from "react";

import { Container, HeadingContainer, StaffContainer, TaskContainer } from "./styledComponents";

const EventView = ({ event }) => {
  const color = {
    '':'#10B981',
    null: "#10B981",
    Install: "#10B981",
    Dismantle: "red",
    Adjustment: "orange",
    Remedial: "#fbb724",

  };
  const eventProp = event.extendedProps;
  
  const styles = () => ({
    color: "white",
    backgroundColor: color[eventProp.type],
  });


  return (
    <Container
      padding="0.5px"
      fontSize="0.8rem"
      color={styles().color}
      backgroundColor={styles().backgroundColor}
    >
{/*       <HeadingContainer>{event.title}</HeadingContainer> */}
      <StaffContainer>
        <b>Date:</b> {eventProp.date2}
      </StaffContainer>
      <StaffContainer>
        <b>Time:</b> {eventProp.time}
      </StaffContainer>
      <StaffContainer>
        <b>Description:</b> {eventProp.description}
      </StaffContainer>
      <TaskContainer>
        <b>Staff:</b> {eventProp.staff}
      </TaskContainer>
    </Container>
  );
};

export default EventView;
