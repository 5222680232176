import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

async function createJob(job) {
  const { data: quoteData, error: quoteError } = await supabase
    .from("quotes")
    .insert({ status: "JobQuoteInternal" })
    .select("id");
  const { data, error } = await supabase.from("jobs").insert({ ...job, id: quoteData[0].id });

  if (error) {
    throw new Error(error.message);
  }

  const result = await supabase
    .from("jobs")
    .update({ job_num: data[0].id })
    .match({ id: data[0].id })
    .select("*,clients:client_id(*)");

  await createAppenateJob(result.data);

  return result.data;
}

export function useCreateJob() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();

  return useMutation((job) => createJob(job), {
    onSuccess: () => {
      queryClient.refetchQueries("jobs");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully created job.`,
      });
    },
    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed creating job",
        content: err?.message,
      });
    },
    mutationFn: createJob,
  });
}

async function createAppenateJob(jobs) {
  /*
    ROWS:
    id - job # - Client Id - Client Name - Client Contact # - Site - Job description - Status - Truck Driver - Supervisor -
  */

  const jobsPayload = [];

  jobs.map((job) =>
    jobsPayload.push([
      job.id,
      job.id,
      `${job.id} - ${job.clients.client_name} - ${job.site}`,
      job.clients.client_name || "",
      "",
      "",
      "",
      job.site || "",
      "",
      job.job_status || "",
      job?.truck_driver || "",
      job?.supervisor || "",
    ]),
  );

  return axios.post("https://metroscaff-server.herokuapp.com/api/data-sync", {
    id: "881ce4f1-618b-4bab-9c1d-af3500032c13",
    data: jobsPayload,
  });
}
