export const typeOptions = [
  { value: 'Bag', label: 'Bag' },
  { value: 'Gumboots', label: 'Gumboots' },
  { value: 'Ear Plugs/ Muffs', label: 'Ear Plugs/ Muffs' },
  { value: 'First Aid Kit', label: 'First Aid Kit' },
  { value: 'Fire Extinguisher', label: 'Fire Extinguisher' },
  { value: 'Hard Hat', label: 'Hard Hat' },
  { value: 'Harness', label: 'Harness' },
  { value: 'Hi Viz Vest (summer)', label: 'Hi Viz Vest (summer)' },
  { value: 'Hi Viz Vest (Winter)', label: 'Hi Viz Vest (Winter)' },
  { value: 'Hi Viz Pullover', label: 'Hi Viz Pullover' },
  { value: ' Black Softshell Jacket', label: ' Black Softshell Jacket' },
  { value: 'Lanyard', label: 'Lanyard' },
  { value: 'Long Sleeve Polo', label: 'Long Sleeve Polo' },
  { value: 'Short Sleeve Polo', label: 'Short Sleeve Polo' },
  { value: 'Level', label: 'Level' },
  { value: 'Ratchet', label: 'Ratchet' },
  { value: 'Rain Jacket', label: 'Rain Jacket' },
  { value: 'Rain Coat', label: 'Rain Coat' },
  { value: 'Rain Trousers', label: 'Rain Trousers' },
  { value: 'Rescue Rope', label: 'Rescue Rope' },
  { value: 'Rope Grab', label: 'Rope Grab' },
  { value: 'Safety Glasses', label: 'Safety Glasses' },
  { value: 'Singlets', label: 'Singlets' },
  { value: 'Shoes (summer)', label: 'Shoes (summer)' },
  { value: 'Shoes (Winter)', label: 'Shoes (Winter)' },
  { value: 'Sling', label: 'Sling' },
  { value: 'Static Line', label: 'Static Line' },
  { value: 'Strop', label: 'Strop' },
  { value: ' Cotton T-Shirt', label: ' Cotton T-Shirt' },
  { value: 'Tie downs', label: 'Tie downs' },
  { value: 'Tool Belt', label: 'Tool Belt' },
  { value: 'Tool Belt Braces', label: 'Tool Belt Braces' },
  { value: "Tape Measure", label: "Tape Measure" },
  { value: "Hammer", label: "Hammer" },
  { value: "Hard Hat Sun Shield", label: "Hard Hat Sun Shield" }
];

export const categoryOptions = [
  { value: "Additional Tools", label: "Additional Tools" },
  { value: "Safety Equipment", label: "Safety Equipment" },
  { value: "Specialized PPE", label: "Specialized PPE" },
  { value: "PPE Standard", label: "PPE Standard" }
];
export const categorizedTypeOptions = [
  { category: "Safety Equipment", types: [
    { value: 'Safety Equipment 1', label: 'Safety Equipment 1' },
    { value: 'Safety Equipment 2', label: 'Safety Equipment 2' },
    { value: 'Safety Equipment 3', label: 'Safety Equipment 3' },
  ]},
  { category: "PPE Standard", types: [
    { value: "PPE Standard 1", label: "PPE Standard 1" },
    { value: 'PPE Standard 2', label: 'PPE Standard 2' },
    { value: 'PPE Standard 3', label: 'PPE Standard 3' },
  ]},
  { category: "PPE Standard", types: [
    { value: 'PPE Standard 1', label: 'PPE Standard 1' },
    { value: 'PPE Standard 2', label: 'PPE Standard 2' },
    { value: 'PPE Standard 3', label: 'PPE Standard 3' },
  ]},
  { category: "Specialized PPE", types: [
    { value: 'Specialized PPE 1', label: 'Specialized PPE 1' },
    { value: 'Specialized PPE 2', label: 'Specialized PPE 2' },
    { value: 'Specialized PPE 3', label: 'Specialized PPE 3' },
  ]},
  { category: "Additional Tools", types: [
    { value: 'Additional Tools 1', label: 'Additional Tools 1' },
    { value: 'Additional Tools 2', label: 'Additional Tools 2' },
    { value: 'Additional Tools 3', label: 'Additional Tools 3' },
  ]}
];
export const categoryDropdowns = [
  "Additional Tools",
  "Safety Equipment",
  "Specialized PPE",
];

export const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const statusDropdowns = [
  "Active",
  "Inactive",
];

export const typeDropdowns = [
  "AEG Hand Drill",
  "Advance Guard Rail",
  "Advance Guardrail - Single Pin",
  "Bag",
  "Boots",
  "Chain",
  "Ear Muffs",
  "Electric Device",
  "Fire Extinguisher",
  "Fleece",
  "Gin Wheel",
  "Hammer Drill",
  "Hard Hat",
  "Harness",
  "Heavy Duty Wrench",
  "Jacket",
  "Lanyard",
  "Mobile/Tablet",
  "Portable Flood Light",
  "Power Cord",
  "Power Tool",
  "Ratchet",
  "Rattle Gun",
  "Reciprocating Saw",
  "Rescue Rope",
  "Rope Grab",
  "Sling",
  "Static Line",
  "Strop",
  "T-Shirt",
  "Tool Battery",
  "Trype 3 Retractable Lanyard",
  "Vest",
];
