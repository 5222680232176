import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import { SideModal, Input, Dropdown, Address, Spinner } from "../../common";
import { statusOptions, trueFalseOptions } from "../../utils";
import { fetchClient, useCreateClient, useUpdateClient } from "../../api/Clients";
import { useCreateContact,fetchAllContacts } from "../../api/ClientContacts";

export function ClientForm({ heading, open, setOpen, formType = "create", setClientId }) {
  const [client, setClient] = useState([]);
  const [mainContactOptions, setMainContactOptions] = useState([])
  const [payload, setPayload] = useState({});

  const createClientMutation = useCreateClient();
  const updateClientMutation = useUpdateClient();
  const createContact = useCreateContact();

  const history = useHistory();
  const { clientId } = useParams();

  useEffect(() => {
    let isCurrent = true;

    if (!open && clientId) {
      history.goBack();
    }

    if (clientId) {
      fetchClient(clientId).then((clientData) => {
        if (isCurrent) setClient(clientData);
      });
      fetchAllContacts(clientId).then((contactsData) => {
        if (isCurrent) {
          const contacts = contactsData.map(e => ({ "value": e.id, "label": e.name }))
          setMainContactOptions(contacts)
        }
      })
    }

    return () => {
      isCurrent = false;
    };
  }, [clientId, open]);

  if (formType === "edit" && !client.id)
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  return (
    <div>
      <Formik
        initialValues={{
          client_name: client?.client_name || "",
          email: client?.email || "",
          phone: client?.phone || "",
          status: client?.status || "Active",
          main_contact: client?.main_contact?.id || null,
          role: "",
          contactName: "",
          contactEmail: "",
          contactphone: "",
          enforce_purchase_order: client?.enforce_purchase_order || false
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const { client_name, email, phone, status, main_contact, enforce_purchase_order,contactName,contactEmail,contactphone,role } = values;
          const clientPayload = {
            client_name,
            email,
            phone,
            status,
            main_contact,
            enforce_purchase_order
          };
          const mainContactPayload = {
            name: contactName,
            email: contactEmail,
            phone: contactphone,
            status: "Active",
            role,
            client_id: null,
          };
          try {
            if (formType === "edit") {
              const result = await updateClientMutation.mutateAsync({
                client: clientPayload,
                clientId: client?.id,
              });
            } else {
              const result = await createClientMutation.mutateAsync(clientPayload);
              // setClientId(result?.[0]?.id);
              mainContactPayload.client_id = result?.[0]?.id;
              const resultcontact = await createContact.mutateAsync(mainContactPayload);
              await updateClientMutation.mutateAsync({
                client: { main_contact: resultcontact[0].id },
                clientId: result?.[0]?.id,
              });
            }

            setOpen(false);
            resetForm();
          } catch (err) {
            console.log("ERROR UPDATING", err);
          }
        }}
        validate={(values) => {
          const errors = {};
          if (formType !== "edit" && !values.contactName)
            errors.contactName = "Contact Name Is Required.";
          if (formType !== "edit" && !values.contactEmail)
            errors.contactEmail = "Contact Email Is Required.";
          return errors;
        }}  
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading={heading}
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType={formType}
          >
            <div className="w-1/2">
              <Input
                title="Company"
                id="client_name"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.client_name}
              />
            </div>
            <div className="flex items-center">
              <Input
                title="Contact #"
                id="phone"
                type="text"
                icon="phone"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.phone}
              />
              <Input
                title="Email"
                id="email"
                type="email"
                icon="email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
              />
            </div>
            <div className="flex items-center">
              <Dropdown
                label="Status"
                id="status"
                options={statusOptions}
                value={values.status}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
              <Dropdown
                label="Quotes Must Have PO"
                id="enforce_purchase_order"
                options={trueFalseOptions}
                value={values.enforce_purchase_order}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>
            {formType === "edit" && (
              <div className="w-1/2">
                <Dropdown
                  label="Main Contact"
                  id="main_contact"
                  options={mainContactOptions}
                  value={values.main_contact}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </div>
            )}
            {formType !== "edit" && (
              <div>
                <h3 className="px-5 pt-3 text-lg font-semibold leading-5">Main contact Info</h3>
                <div className="flex items-center">
                  <Input
                    title="Contact Name"
                    id="contactName"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.contactName}
                    error={errors.contactName}
                  />
                </div>
                <div className="flex items-center">
                  <Input
                    title="Email"
                    id="contactEmail"
                    type="email"
                    icon="email"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.contactEmail}
                    error={errors.contactEmail}
                  />
                  <Input
                    title="Contact #"
                    id="contactphone"
                    type="number"
                    icon="phone"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.contactphone}
                  />
                  <Input
                    title="Role"
                    id="role"
                    type="text"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.role}
                  />
                </div>
              </div>
            )}
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
