import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../../supabase";
import { createEDinvoice } from "../createEDinvoice";
import { createHire } from "../../WeeklyHire/createHire";

import { useNotificationStore } from "../../../store/notifications";

async function createVariation(payload) {
  const total_cost = payload?.total_cost || 0;
  const weekly_hire_amount = payload?.weekly_hire_amount || 0;
  delete payload.total_cost;
  delete payload.weekly_hire_amount;
  const { data, error } = await supabase.from("job_tasks").insert(payload);

  if (error) {
    throw new Error(error.message);
  }

  createEDinvoice({
    PO_Number: data[0].PO_Number,
    job_id: Number(data[0].job_id),
    task_id: Number(data[0].id),
    zone: "",
    zone_label: "",
    type: "Variation",
    description: data[0].description,
    erect_percent: 0,
    dismantle_percent: 0,
    complete_percent: 0,
    erect: total_cost * 0.7,
    dismantle: total_cost * 0.3,
    invoiced: 0,
    balance: 0,
    ed_total: total_cost,
  });

  if (weekly_hire_amount > 0) {
    createHire({
      
        job_id: Number(data[0].job_id),
        zone: data[0].zone,
        zone_label: data[0].zone_label,
        type: "Variation",
        description: data[0].description,
        on_hire: "No",
        date_on_hire: "",
        weekly_hire_rate: weekly_hire_amount,
        completed_date: "",
        handover_url: "",
        task_id: Number(data[0].id),
        status: "Pending",
        xeroReference: "",
    })
  }
  await createAppenateTask(data);
  return data;
}

export function useCreateVariation() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();

  return useMutation((payload) => createVariation(payload), {
    onSuccess: () => {
      queryClient.refetchQueries("variation_tasks");
      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully created task.`,
      });
    },
    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed creating task",
        content: err?.message,
      });
    },
    mutationFn: createVariation,
  });
}

async function createAppenateTask(jobs) {
  const tasksPayload = [];

  jobs.map((task) =>
    tasksPayload.push([
      task.id,
      task?.job_id || "",
      task.zone || "",
      task.zone_label || "",
      task.type || "",
      task.description || "",
      task.complete || "",
    ]),
  );

  return axios.post("https://metroscaff-server.herokuapp.com/api/data-sync", {
    id: "091cc2e0-831e-4341-8108-af3500047302",
    data: tasksPayload,
  });
}
