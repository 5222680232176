import { useMutation } from "react-query";
import supabase from "../../supabase";

export async function createImages(image) {
  // console.log("Guarde imagen va: ", image);
  const { data, error } = await supabase.from("quote_files").upsert(image);

  if (error) {
    console.log(error);
    throw new Error(error.message);
  }
  return data;
}

export const useCreateImages = () =>
  useMutation((image) => createImages(image), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: createImages,
  });
