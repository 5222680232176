import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

export async function updateHireByTask({ hire, tasks }) {
  const promises = tasks.map((taskId) =>
    supabase.from("weekly_hire_invoices").update(hire).eq("task_id", taskId),
  );

  const results = await Promise.all(promises);

  const errors = results.filter((result) => result.error).map((result) => result.error.message);

  if (errors.length > 0) {
    throw new Error(errors.join(", "));
  }

  return results.map((result) => result.data);
}

export const useUpdateHireByTask = () => {
  const { addNotification } = useNotificationStore();
  const queryClient = useQueryClient();

  return useMutation((hire) => updateHireByTask(hire), {
    onSuccess: (data) => {
      queryClient.refetchQueries("weeklyHires");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: `Successfully updated hire invoice.`,
      });
    },

    onError: (err) => {
      addNotification({
        isSuccess: false,
        heading: "Failed updating hire invoice",
        content: err?.message,
      });
    },
    mutationFn: updateHireByTask,
  });
};
