import { Card, Row, Button, Text, Modal, ModalBody, ModalContent, Image } from "@nextui-org/react";
import { useState } from "react";
import { ExternalLinkIcon, DocumentTextIcon, XIcon } from "@heroicons/react/solid";

export const Thumbnails = ({ link, comment }) => {
  const [visible, setVisible] = useState(false);
  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
    console.log("closed");
  };
  return (
    <div>
      <Card css={{ w: "300px", h: "300px" }}>
        <button
          type="button"
          onClick={() => setVisible(true)}
          className="flex items-center justify-center w-full h-full"
        >
          <Card.Body css={{ p: 0 }}>
            {link.split(".").pop().toLowerCase() === "pdf" ? (
              <div className="flex flex-col items-center justify-center h-full">
                <DocumentTextIcon className="h-12 w-12 text-gray-500" />
                <Text css={{ color: "inherit" }} size={12} weight="bold" transform="uppercase">
                  {link.split("/").pop()}
                </Text>
              </div>
            ) : (
              <Card.Image
                src={link}
                objectFit="contain"
                width="100%"
                height="100%"
                alt="Relaxing app background"
              />
            )}
          </Card.Body>
        </button>
        <Card.Footer
          isBlurred
          css={{
            position: "absolute",
            bgBlur: "#0f111466",
            borderTop: "$borderWeights$light solid $gray800",
            bottom: 0,
            zIndex: 1,
            bg: "white",
          }}
        >
          <Row className="flex justify-between">
            <Text css={{ color: "inherit" }} size={12} transform="uppercase">
              {link.split("/").pop()}
              <Text css={{ color: "inherit" }} size={12} weight="bold" transform="uppercase">
                Comment:{" "}
              </Text>
              {comment}
            </Text>
            <Button
              flat
              auto
              rounded
              css={{ color: "#2590EB", bg: "white" }}
              onClick={() => window.open(link, "_blank")}
              className="ml-auto"
            >
              <ExternalLinkIcon className="h-5 w-5" />
            </Button>
          </Row>
        </Card.Footer>
      </Card>
      <Modal
        noPadding
        width="50%"
        style={{ cursor: "default" }}
        open={visible}
        onClose={closeHandler}
        className=""
      >
        <div
          className={`relative p-4 ${link.split(".").pop().toLowerCase() === "pdf" ? "py-20" : ""}`}
        >
          <XIcon
            className="h-6 w-6 text-gray-500 absolute top-2 right-2 cursor-pointer"
            onClick={closeHandler}
          />
          {link.split(".").pop().toLowerCase() === "pdf" ? (
            <div className="flex flex-col items-center justify-center h-full">
              <DocumentTextIcon className="h-12 w-12 text-gray-500" />
            </div>
          ) : (
            <div className="p-4">
              <Image src={link} alt="" />
            </div>
          )}
          <div className=" bottom-2 left-2">
            <Text css={{ color: "inherit" }} size={12} transform="uppercase">
              {link.split("/").pop()}
            </Text>
            <Text css={{ color: "inherit" }} size={12} weight="bold" transform="uppercase">
              Comment:{" "}
            </Text>
            <Text css={{ color: "inherit" }} size={14}  transform="uppercase">
            {comment}
            </Text>
          </div>
          <div className="absolute bottom-2 right-2">
            <Button
              flat
              auto
              rounded
              css={{ color: "#2590EB", bg: "white" }}
              onClick={() => window.open(link, "_blank")}
              className="ml-auto"
            >
              <ExternalLinkIcon className="h-5 w-5" />
              Open in new tab
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
