import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Table, Spinner, Badge } from "../../common";

import { JobsApi } from "../../api";

export const JobvsHours = ({ jobId }) => {
  const timesheetsQuery = JobsApi.useFetchVisitTimesheets(jobId);
  const taskQuery = JobsApi.useTasks(jobId);

  if (timesheetsQuery.isLoading || taskQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!timesheetsQuery.data) return null;

  const dataForTable = [{ estatedHours: 0, usedHours: 0 }];

  for (const row of timesheetsQuery.data) {
    const totalStaff = row?.staff_ids?.length + 1;
    const start = moment(row.time_in, "HH:mm");
    const finish = moment(row.time_off, "HH:mm");
    const travelTime = parseFloat(row.travel_time);

    if (row.time_off && finish) {
      const duration = moment.duration(finish.diff(start));
      const hours = duration.asHours() * totalStaff;

      if (row.travel_time) {
        dataForTable[0].usedHours += hours + travelTime;
      } else {
        dataForTable[0].usedHours += hours;
      }
    }
  }

  for (const row of taskQuery.data) {
    dataForTable[0].estatedHours += Number(row.total_hours);
  }

  return (
    <div className="w-full mx-auto mt-8">
      <div>
        <h2 className="text-lg px-8 mb-2 leading-6 font-large text-gray-900 mt-6">
          Quoted vs Actual Hours
        </h2>

        <Table
          cols={[
            {
              Header: "Estimated Hours",
              accessor: "estatedHours",
              Cell: ({ row }) => <span>{row?.original?.estatedHours?.toFixed(2)}</span>,
            },
            {
              Header: "Total Hours used",
              accessor: "usedHours",
              Cell: ({ row }) => <span>{row?.original?.usedHours?.toFixed(2)}</span>,
            },
            // {
            //   Header: "Status",
            //   Cell: ({ row }) => {
            //     const type = row?.original?.status;
            //     return <Badge type={type} text={type} />;
            //   },
            //   width: 60,
            //   accessor: "status",
            // },
          ]}
          tableData={dataForTable}
          searchPlaceholder="Search Timesheets"
          displayPagination={timesheetsQuery?.data?.length}
        />
      </div>
    </div>
  );
};
