import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Checkbox } from "primereact/checkbox";
import { Icon } from "leaflet";
import { TagsApi, VisitsApi } from "../../api";
import { markerIcon } from "../../utils/markerIcon";

export const Map = () => {
  const svgBlueUrl = `data:image/svg+xml;base64,${btoa(
    markerIcon.replace(/fill=""/g, `fill="blue"`),
  )}`;
  const svgGreenUrl = `data:image/svg+xml;base64,${btoa(
    markerIcon.replace(/fill=""/g, `fill="green"`),
  )}`;
  const blueIcon = new Icon({
    iconUrl: svgBlueUrl,
    iconSize: [30, 30],
  });
  const greenIcon = new Icon({
    iconUrl: svgGreenUrl,
    iconSize: [30, 30],
  });

  const { data: visitsData } = VisitsApi.useVisits();
  const { data: tagsData } = TagsApi.useTags();

  const [showVisits, setShowVisits] = useState(true);
  const [showTags, setShowTags] = useState(true);

  return (
    <div>
      <div className="flex gap-2 m-4">
        <div>
          <Checkbox
            inputId="showVisits"
            checked={showVisits}
            onChange={() => setShowVisits(!showVisits)}
          />
          <label htmlFor="showVisits" className="ml-2">Show Visits</label>
        </div>
        <div>
          <Checkbox inputId="showTags" checked={showTags} onChange={() => setShowTags(!showTags)} />
          <label htmlFor="showTags" className="ml-2">Show Tags</label>
        </div>
      </div>
      <MapContainer
        style={{ height: "87vh", width: "100%", zIndex: 1 }}
        center={[-36.848461, 174.763336]}
        zoom={12}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {showVisits &&
          visitsData?.map((visit, index) =>
            visit?.jobs?.lat && visit?.jobs?.long ? (
              <Marker icon={greenIcon} position={[visit?.jobs?.lat, visit?.jobs?.long]} key={index}>
                <Popup>{`Job ${visit?.jobs?.id} - ${visit?.jobs?.site}`}</Popup>
              </Marker>
            ) : null,
          )}

        {showTags &&
          tagsData?.map((tag, index) =>
            tag?.jobs?.lat && tag?.jobs?.long ? (
              <Marker icon={blueIcon} position={[tag?.jobs?.lat, tag?.jobs?.long]} key={index}>
                <Popup>{`Job ${tag?.jobs?.id} - ${tag?.jobs?.site}`}</Popup>
              </Marker>
            ) : null,
          )}
      </MapContainer>
    </div>
  );
};
