import { useQuery } from "react-query";

import supabase from "../../supabase";

export async function getQuotePowerlines(quote_id) {
  const { data, error } = await supabase
    .from("quote_additional_conditions")
    .select("*")
    .eq("quote_id", quote_id)
    .eq("name", "checkbox3")
  if (error) throw new Error(error.message);

  return data

}
